import CommonAPIService from './CommonHandler';

export class PoomDanAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the tapes
  getPoomDan(router) {
    const url = '/api/poomdan';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getPoomDanByID(tape_id, router) {
    const url = `/api/poomdan/${tape_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  addPoomDan(tape, router) {
    const url = '/api/poomdan';
    return this.CommonAPIService.postCall(url, tape, router);
  }

  updatePoomDan(tape_id, parms, router) {
    const url = `/api/poomdan/${tape_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }

  deletePoomDan(tape_id, router) {
    const url = `/api/poomdan/${tape_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default PoomDanAPIService;
