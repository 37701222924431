<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Poomsae Table</h4>
                <p class="card-category">Create or Edit Poomsae</p>
                <p class="card-category">
                  <el-button type="primary" @click="openModal('create')" icon="">Add New Poomsae</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row align-items-center">
              <div class="col-sm-8" style="padding-right: 0;"> <!-- Remove padding if needed -->
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filteredPoomsae"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;"> <!-- Minimal padding for spacing -->
                <el-button type="primary" @click="filterPoomsae" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="techTable" style="width: 100%">
        <el-table stripe :data="filteredPoomsae" style="width: 100%" height="65vh" @sort-change="handleSortChange">
          <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-4">
                  {{ scope.row.order }}
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" sortable="custom" label="Info" :min-width="500" align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-6">
                  <el-input placeholder="Name" v-model="scope.row.name" @change="updatePoomsae(scope.row.id)"></el-input>
                  <el-input placeholder="Korean Name" v-model="scope.row.korean_name" @change="updatePoomsae(scope.row.id)"></el-input>
                </div>
                <div class="col-sm-6">
                  <el-input placeholder="Description" v-model="scope.row.description" @change="updatePoomsae(scope.row.id)"></el-input>
                  <el-input placeholder="Taeguk #" v-model="scope.row.taeguk_number" @change="updatePoomsae(scope.row.id)"></el-input>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Poom/Dan" :min-width="100" align="center">
            <template v-slot="scope">
              <el-select filterable v-model="scope.row.poom_dan_id" placeholder="Poom or Dan" style="width: 100%"
                         @change="updatePoomsae(scope.row.id)">
                <el-option v-for="item in poomDan"
                           :key="item.id" :value="item.id" :label="item.name">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="pdf"  label="PDF" :min-width="200" align="center">
            <template v-slot="scope">
              <div v-if="scope.row.pdf">
                <el-button type="primary" @click="downloadPDF(scope.row.pdf, 'poomsae', scope.row.id)" icon="">{{ scope.row.pdf }}</el-button>
                <el-button type="danger" @click="removeFile(scope.row.id)" icon="el-icon-delete"></el-button>
              </div>
              <div v-else>
                <el-button type="primary" @click="preUpload('pdf', scope.row.id)" icon="">Upload PDF</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="url"  label="URL" :min-width="200" align="center">
            <template v-slot="scope">
              <el-input placeholder="Media URL" v-model="scope.row.media" style="width: 100%" @change="updatePoomsae(scope.row.id)"></el-input>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template v-slot="scope">
              <el-button type="danger" @click="deletePoomsae(scope.row.id)" icon="el-icon-delete"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="OK"></el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Upload PDF"
      :visible.sync="modals.pdf">
      <div class="text-center">
        <div class="row">
          <div class="col-sm-12">
            <input type="file" ref="pdfInput" @change="uploadPDF"/>
            <el-button type="primary" @click="uploadFile" icon="">Upload</el-button>
            <p v-if="message">{{ message }}</p>
          </div>
        </div>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('pdf')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Create New Poomsae"
      :visible.sync="modals.create">
      <div>
        <div class="row">
          <div class="col-sm-4">
            <label>Name:</label>
              <el-input placeholder="Name" v-model="name"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Korean Name:</label>
              <el-input placeholder="Korean Name" v-model="korean_name"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Description:</label>
            <el-input placeholder="Description" v-model="description"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Taeguk Number:</label>
            <el-input placeholder="Media URL" v-model="taeguk_number"></el-input>
          </div>
          <div class="col-sm-4">
            <label>Notes:</label>
            <el-input placeholder="Notes" v-model="notes"></el-input>
          </div>
          <div class="col-sm-4">
            <label>URL:</label>
            <el-input placeholder="Media URL" v-model="media"></el-input>
          </div>
          <br>
          <div class="col-sm-12 text-center">
            <br>
            <el-checkbox v-model="tested" size="large" border>Tested</el-checkbox>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="createPoomsae('create')" icon="">OK</el-button>
          <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { Dialog, Button, Input, Checkbox, Table, TableColumn, Select, Option } from 'element-ui';
import PoomsaeAPIService from "src/servicehandlers/PoomsaeAPIService";
import { FileAPIService } from 'src/servicehandlers/FileHandler';
import PoomDanAPIService from "../../../../servicehandlers/PoomDanAPIService";

const poomsaeAPIService = new PoomsaeAPIService();
const fileAPI = new FileAPIService();
const poomDanAPIService = new PoomDanAPIService();

export default {
  name: "PoomsaeAdmin",
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      spinning: false,
      selected_poomsae_id: null,
      pdfFile: null,
      korean_name: null,
      tested: null,
      notes: null,
      taeguk_number: null,
      message: '',
      filename: null,
      name: null,
      description: null,
      pdf: null,
      media: null,
      last_order: null,
      poomsae: [],
      filteredPoomsae: [],
      mediaFile: null,
      error_txt: null,
      success_txt: null,
      poomsae_list2: [],
      ranks: [],
      techniques: [],
      show_poomsae_ranks: false,
      show_poomsae_techniques: false,
      modals: {
        error: false,
        success: false,
        person: false,
        create: false,
        pdf: false,
        media: false
      },
      search: '',
      poomDan: []
    }
  },
  mounted() {
    this.getPoomsae();
    this.getPoomDan()
  },
  methods: {
    getPoomDan(){
      return poomDanAPIService.getPoomDan(this.$router)
        .then(response => {
          this.poomDan = response
        })
    },
    async downloadPDF(name, type, id) {
      this.filename = name;
      this.spinning = true;
      return fileAPI.getPDFFile(name, type, id, this.$router)
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.filename;
        this.spinning = false;
        link.click();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Poomsae PDF download failed! ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
    },
    preUpload(name, id) {
      this.selected_poomsae_id = id;
      this.openModal(name);
    },
    removeFile(id) {
      const parms = {
        pdf: null,
      };
      return poomsaeAPIService.updatePoomsae(id, parms, this.$router)
      .then(() => {
        this.getPoomsae();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'PDF clearing failure: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadFile() {
      this.spinning = true;
      this.files = this.$refs.pdfInput.files[0];
      let formData = new FormData();
      for (const fle of this.$refs.pdfInput.files) {
        formData.append('pdfInput', fle);
        formData.append('type', 'poomsae');
        formData.append('id', this.selected_poomsae_id);
      }
      return fileAPI.uploadFile(formData, this.$router)
      .then((response) => {
        const self = this;
        this.success_txt = 'PDF upload success';
        this.spinning = false;
        this.pdfFile = null;
        this.closeModal('pdf');
        this.openModal('success');
        this.getPoomsae();
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    uploadPDF(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const fileType = /pdf/;
        if (!fileType.test(files[0].type)) {
          this.message = "Please select a valid PDF file.";
          return;
        }
        this.pdfFile = files[0];
        this.message = '';
      }
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.poomsae.length; i += 1) {
        const id = this.poomsae[i].id;
        this.poomsae[i].order = i + 1;
        promises.push(poomsaeAPIService.updatePoomsae(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        this.getPoomsae();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'File upload error: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    updatePoomsae(id) {
      for (const poom of this.poomsae) {
        if (id === poom.id) {
          return poomsaeAPIService.updatePoomsae(id, poom, this.$router)
          .then(() => {
            const self = this;
            this.success_txt = 'Poomsae update successful';
            /*this.openModal('success');
            setTimeout(function () {
              self.modals['success'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs*/
          })
          .catch((error) => {
            const self = this;
            this.error_txt = 'Poomsae update failure: ' + error;
            this.openModal('error');
            this.spinning = false;
            setTimeout(function () {
              self.modals['error'] = false; // Use your variable name
            }, 1500); // Hide after 5 secs
          });
        }
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    deletePoomsae(id) {
      return poomsaeAPIService.deletePoomsae(id, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Delete Poomsae successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
        this.getPoomsae();
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error deleting Poomsae: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    getPoomsae() {
      return poomsaeAPIService.getPoomsae(this.$router)
      .then((response) => {
        for (const poom of response) {
          this.last_order = poom.order;
        }
        for (const poom of response) {
          this.poomsae_list2.push(poom);
        }
        this.poomsae = response;
        this.filteredPoomsae = this.poomsae
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error getting Poomsae: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    createPoomsae() {
      this.closeModal('create');
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }
      const poomsae = {
        order,
        name: this.name,
        description: this.description,
        media: this.media,
        notes: this.notes,
        short_name: this.short_name,
        korean_name: this.korean_name,
        taeguk_number: this.taeguk_number,
        tested: this.tested,
      };
      return poomsaeAPIService.addPoomsae(poomsae, this.$router)
      .then((response) => {
        this.poomsae.push(response);
        this.name = null;
        this.description = null;
        this.media = null;
        const self = this;
        this.success_txt = 'Add Poomsae successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        const self = this;
        this.error_txt = 'Error Creating Poomsae: ' + error;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      });
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.poomsae[index];
        this.poomsae.splice(index, 1);
        this.poomsae.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.poomsae.length - 1) {
        const temp = this.poomsae[index];
        this.poomsae.splice(index, 1);
        this.poomsae.splice(index + 1, 0, temp)
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.poomsae.length; i++){
        this.poomsae[i].order = i+1
      }
      this.updatePoomsae(item.id)
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredPoomsae.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredPoomsae.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    filterPoomsae(){
      try {
        this.filteredPoomsae = this.poomsae.filter(
          (data) =>
            !this.search ||
            data.name.toLowerCase().includes(this.search.toLowerCase())
        )
      } catch (e) {
        this.error_txt = 'One or more names missing'
        this.openModal('error')
      }
    },
  }
}
</script>

<style scoped>
el-input{
  width: 100%;
}
</style>
