<template>
<div>
  <card v-if="spinning">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </card>
  <card>
    <div class="row">
      <div class="col-sm-12 text-center">
        <h3>User Admin Page</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center">
        <label>Find A User to Edit</label>
        <br>
        <el-select filterable clearable v-model="selected_user_id" placeholder="Select a User to Edit"
                   @input="selectUser" style="width: 75%">
        <el-option v-for="user in users"
                   :key="user.id" :value="user.id" :label="returnUserFullName(user)">
        </el-option>
        </el-select>
      </div>
      <div class="col-md-4">
        <el-button class="float-right"  v-if="!this.$store.getters.inProxy" @click="proxyLogin" type="primary">Proxy as User</el-button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-2">
        <label for="username">Username:</label>
        <el-input id="username" placeholder="Username" v-model="username"></el-input>
      </div>
      <div class="col-sm-2">
        <label for="firstname">First Name:</label>
        <el-input id="firstname" placeholder="First Name" v-model="first_name"></el-input>
      </div>
      <div class="col-sm-2">
        <label for="lastname">Last Name:</label>
        <el-input id="lastname" placeholder="Last Name" v-model="last_name"></el-input>
      </div>
      <div class="col-sm-2">
        <label for="email">Email:</label>
        <el-input id="email" placeholder="Email" v-model="email"></el-input>
      </div>
      <div class="col-sm-2">
        <label for="phone">Phone Number:</label>
        <el-input id="phone" placeholder="Phone" v-model="phone_number"></el-input>
      </div>
      <div class="col-sm-2">
        <label for="gender">Gender:</label>
        <el-select id="gender" v-model="gender" placeholder="Gender" style="width: 100%">
          <el-option v-for="item in genderOptions"
                     :key="item" :value="item" :label="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-2 align-content-center">
        <label for="instructor">Master Instructor Name:</label>
        <el-input id="instructor" placeholder="Master Instructor" v-model="master_instructor_name"></el-input>
      </div>
      <div class="col-sm-2 align-content-center">
        <label for="studio">Studio Name:</label>
        <el-input id="studio" placeholder="Studio Name" v-model="studio_name"></el-input>
      </div>
      <div class="col-sm-2 align-content-center" v-if="current_rank && selected_user_id">
        <label for="rank">Rank: {{current_rank.name}}</label>
        <promotion :next_rank="next_rank" :user_id="selected_user_id" :ranks="ranks" @reload="selectUser" :current_rank="current_rank"></promotion>
      </div>
      <div class="col-sm-2 align-content-center" v-else>
        <label for="rank">Rank:</label>
        <el-select filterable v-model="rank_id" placeholder="Select a Rank"
                   style="width: 100%" id="rank">
          <el-option v-for="rank in ranks"
                     :key="rank.id" :value="rank.id" :label="rank.name">
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-2 align-content-center">
        <label for="beltrank">Belt Rank:</label>
        <el-select filterable v-model="belt_rank_id" placeholder="Select a Belt Rank"
                   style="width: 100%" id="beltrank">
          <el-option v-for="rank in belt_ranks"
                     :key="rank.id" :value="rank.id" :label="rank.name">
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-2 align-content-center">
        <label for="weight">Weight:</label>
        <el-input-number style="width: 100%" id="weight" class="float-right" v-model="weight"></el-input-number>
      </div>
      <div class="col-sm-2 align-content-center">
        <label for="birthday">Birthday:</label>
<!--        <el-input-number style="width: 100%" id="age" class="float-right" v-model="age"></el-input-number>-->
        <el-date-picker v-model="birthday" type="date" placeholder="Pick a birthday" @change="updatePoomDan"></el-date-picker>
        <label for="poomdan">Poom/Dan:</label>
        <el-select filterable v-model="poom_dan_id" placeholder="Select Poom or Dan"
                   style="width: 100%" id="poomdan">
          <el-option v-for="item in poom_dan_options"
                     :key="item.id" :value="item.id" :label="item.name">
          </el-option>
        </el-select>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-6">
        <div class="row align-items-center">
          <div class="col-sm-8">
            <label for="password">Password:</label>
            <el-input id="password" placeholder="Password" v-model="password" v-if="passwordVisible">
              <i slot="suffix" @click="togglePassword" class="el-input__icon el-icon-view"></i>
            </el-input>
            <el-input id="password" placeholder="Password" type="password" v-model="password" v-else>
              <i slot="suffix" @click="togglePassword" class="el-input__icon el-icon-view"></i>
            </el-input>
          </div>
          <div class="col-sm-4 text-center">
            <br>
            <el-button id="changepw" type="primary" @click="changePassword" icon="">Change Password</el-button>
          </div>
        </div>
      </div>
      <div class="col-sm-3 text-center align-items-center">
        <el-checkbox v-model="enabled" size="large" border>Enabled</el-checkbox>
      </div>
      <div class="col-sm-3 text-center align-items-center">
        <el-checkbox v-model="validated" size="large" border>Validated</el-checkbox>
        </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-4 text-center">
        <el-button type="danger" @click="clear" icon="">Clear</el-button>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-4 text-center">
        <el-button type="success" @click="createuser" icon="">Create or Update User</el-button>
      </div>
    </div>
  </card>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>Success: {{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
      </span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {phone} from 'phone';
import { Dialog, Option, Select, Button, Checkbox, InputNumber, DatePicker } from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';
import { RanksAPIService } from 'src/servicehandlers/RanksAPIService';
import BeltRanksAPIService from "../../../../../servicehandlers/BeltRanksAPIService";
import CommonAPIService from "src/servicehandlers/CommonHandler";
import Promotion from "../../../Components/Promotion.vue";
import crypto from "crypto-js";
import PoomDanAPIService from "../../../../../servicehandlers/PoomDanAPIService";

const apiService = new CommonAPIService();
const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const beltRanksAPIService = new BeltRanksAPIService();
const poomDanAPIService = new PoomDanAPIService();

export default {
  name: "UserAdmin",
  components: {
    Promotion,
    [Dialog.name]: Dialog,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [InputNumber.name]: InputNumber,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      selected_user_id: null,
      error_txt: null,
      success_txt: null,
      ranks: [],
      spinning: false,
      username: null,
      email: null,
      rank_id: null,
      phone: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_number: null,
      studio_name: null,
      master_instructor_name: null,
      belt_rank_id: null,
      belt_ranks: [],
      weight: null,
      birthday: '',
      first_name: null,
      last_name: null,
      password: null,
      enabled: true,
      validated: false,
      users: [],
      gender: null,
      genderOptions: ['Male', 'Female'],
      last_rank: null,
      current_rank: null,
      next_rank: null,
      passwordVisible: false,
      poom_dan_options: [],
      poom_dan_id: null,
    }
  },
  mounted() {
    //console.clear()
    this.getAllUsers();
    this.getAllRanks();
    this.getAllBeltRanks();
    this.getPoomDan();
    let userID = this.$store.getters.proxyUserId
    if (userID){
      this.selected_user_id = userID
      this.selectUser()
      this.$store.dispatch('ADD_PROXY_USERID', 0)
    }
  },
  methods: {
    getPoomDan(){
      return poomDanAPIService.getPoomDan(this.$router)
        .then(response => {
          this.poom_dan_options = response
          this.poom_dan_options.pop()
        })
    },
    togglePassword(){
      this.passwordVisible = !this.passwordVisible
    },
    changePassword() {
      this.spinning = true;
      const parms = {
        'user_id': this.selected_user_id,
        'password': this.password,
      };
      return usersAPIService.changePassword(parms, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_txt = 'Successfully changed password. ';
        this.openModal('success');
      })
      .catch((error) => {
        this.error_txt = 'Error changing password: ' + error;
        this.openModal('error');
        this.spinning = false;
      });
    },
    getAllBeltRanks() {
      this.spinning = true;
      return beltRanksAPIService.getBeltRanks(this.$router)
      .then((ranks) => {
        for (const rank of ranks) {
          rank.value = rank.id;
          rank.text = rank.name;
        }
        this.belt_ranks = ranks;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting belt ranks: ' + error;
        this.openModal('error');
      });
    },
    getAllRanks() {
      this.spinning = true;
      return ranksAPIService.getRanks(this.$router)
      .then((ranks) => {
        for (const rank of ranks) {
          rank.value = rank.id;
          rank.text = rank.name;
        }
        this.ranks = ranks;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error getting ranks: ' + error;
        this.openModal('error');
      });
    },
    getAllUsers() {
      this.spinning = true;
      return usersAPIService.getUserList(this.$router)
      .then((allUsers) => {
        for (const user of allUsers) {
          user.text = user.first_name + ' ' + user.last_name;
          user.value = user.id;
        }
        this.users = allUsers;
        this.spinning = false;
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = error;
        this.openModal('error');
      });
    },
    clear() {
      this.first_name = null;
      this.last_name = null;
      this.selected_user_id = null;
      this.master_instructor_name = null;
      this.belt_rank_id = null;
      this.studio_name = null;
      this.last_name = null;
      this.password = null;
      this.username = null;
      this.email = null;
      this.enabled = true;
      this.rank_id = null;
      this.phone = null;
      this.address1 = null;
      this.address2 = null;
      this.phone_number = null;
      this.city = null;
      this.state = null;
      this.zip = null;
      this.weight = null;
      this.birthday = ''
      this.poom_dan_id = null
      this.gender = null
      this.validated = null;
      this.current_rank = null;
      this.last_rank = null;
      this.next_rank = null;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    selectUser() {
      if (this.selected_user_id) {
        this.spinning = true;
        usersAPIService.getUserById(this.selected_user_id)
          .then((user) => {
            this.username = user.username;
            this.email = user.email;
            this.first_name = user.first_name;
            this.last_name = user.last_name;
            this.enabled = user.enabled;
            this.rank_id = user.rank_id;
            this.phone_number = user.phone;
            this.master_instructor_name = user.master_instructor_name;
            this.studio_name = user.studio_name;
            this.belt_rank_id = user.belt_rank_id;
            this.weight = user.weight;
            this.birthday = new Date(crypto.AES.decrypt(user.birthday, process.env.VUE_APP_SECRET_KEY).toString(crypto.enc.Utf8))
            this.updatePoomDan()
            this.gender = user.gender
            this.validated = user.validated;
            this.spinning = false;
            for (let i = 0; i < this.ranks.length; i++){
              if (this.ranks[i].id === this.rank_id){
                if (i >= 1){
                  this.last_rank = this.ranks[i - 1]
                } else {
                  this.last_rank = null
                }
                this.current_rank = this.ranks[i]
                if (i <= this.ranks.length - 1){
                  this.next_rank = this.ranks[i + 1]
                  if (this.next_rank.is_sub_rank){
                    let count = 2
                    while (this.next_rank.is_sub_rank){
                      this.next_rank = this.ranks[i + count]
                      count++
                    }
                  }
                  if (this.ranks.some(data => data.head_rank_id === this.next_rank.id)){
                    this.next_rank = this.ranks[i + 2]
                  }
                } else {
                  this.next_rank = null
                }
                break
              }
            }
          })
          .catch((error) => {
            this.error_txt = error;
            this.spinning = false;
            this.openModal('error');
          });
      } else{
        this.clear()
      }
    },
    updatePoomDan(){
      const today = new Date();
      let age = today.getFullYear() - this.birthday.getFullYear();
      const monthDifference = today.getMonth() - this.birthday.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < this.birthday.getDate())) {
        age--;
      }
      if (age < 15){
        this.poom_dan_id = this.poom_dan_options[0].id
      } else {
        this.poom_dan_id = this.poom_dan_options[1].id
      }
    },
    createuser() {
      this.spinning = true;
      if (!this.username) {
        this.spinning = false;
        this.error_txt = 'No username passed in';
        this.openModal('error');
        return;
      }
      if (!this.last_name) {
        this.spinning = false;
        this.error_txt = 'No last name passed in';
        this.openModal('error');
        return;
      }
      if (!this.first_name) {
        this.spinning = false;
        this.error_txt = 'No first name passed in';
        this.openModal('error');
        return;
      }
      if (!this.selected_user_id && !this.password) {
        this.spinning = false;
        this.error_txt = 'No password was set';
        this.openModal('error');
        return;
      }
      if (!this.selected_user_id && this.password && this.password.length < 8) {
        this.spinning = false;
        this.error_txt = 'Password length must be set to 8 characters or more';
        this.openModal('error');
        return;
      }
      this.phone = phone(this.phone_number);
      if (!this.rank_id) {
        this.spinning = false;
        this.error_txt = 'Missing rank!';
        this.openModal('error');
        return;
      }

      const parms = {
        username: this.username,
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        enabled: this.enabled,
        rank_id: this.rank_id,
        phone: this.phone.phoneNumber,
        master_instructor_name: this.master_instructor_name,
        studio_name: this.studio_name,
        belt_rank_id: this.belt_rank_id,
        weight: this.weight,
        birthday: crypto.AES.encrypt(this.birthday.toDateString(), process.env.VUE_APP_SECRET_KEY).toString(),
        poom_dan_id: this.poom_dan_id,
        gender: this.gender,
        validated: this.validated,
      };
      if (this.password) {
        parms.password = this.password;
      }
      if (this.selected_user_id) {
        parms.id = this.selected_user_id;
      }
      usersAPIService.createUser(parms, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_txt = 'Successfully updated or created user: ';
        this.openModal('success');
        this.clear();
        this.spinning = false;
        this.getAllUsers()
      })
      .catch((error) => {
        this.spinning = false;
        this.error_txt = 'Error creating user: ' + error;
        this.openModal('error');
      });
    },
    returnUserFullName(user){
      return user.first_name + ' ' + user.last_name
    },
    proxyLogin(){
      if (this.selected_user_id !== null){
        let loggedIn = this.$store.getters.loggedIn;
        let errors = []
        return apiService.proxylogin(this.selected_user_id, this.$store.getters.userId, false, this.$router, this.$store, errors)
          .then((response) => {
            if (response && response.success) {
              loggedIn = this.$store.getters.loggedIn;
              if (loggedIn) {
                this.goToHome()
              }
            } else {
              this.error_txt = response.msg;
              this.openModal('error');
            }
          })
          .catch((error) => {
            this.error_txt = error;
            this.openModal('error');
          });
      } else  {
        this.error_txt = "No user Selected";
        this.openModal('error');
      }
    },
    goToHome() {
      // this.rightsArray = this.loginCheckMixin(['page-claim', 'page-firm']);
      // let homePage=this.$store.state.userPreferences.home === 'firms' ? 'FirmsSearch' : 'ClaimsSearch';
      // let homePage=this.$store.state.userPreferences.home === 'claims' ? 'ClaimsSearch' : 'FirmsSearch';
      // homePage is preferred page if user has rights to see, else other search
      // based on all having at least one of these two page rights
      // if (!this.rightsArray.includes('page-firm')) homePage = 'ClaimsSearch';
      // if (!this.rightsArray.includes('page-claim')) homePage = 'FirmsSearch';
      this.$router.push({
        name: 'main'
      });
      location.reload()
    },
    promoteUser(id){
      if (this.next_rank){
        return usersAPIService.changeUserRankTo(id, this.next_rank.id, this.$router)
          .then(() => {
            this.selectUser()
          })
          .catch((e) => {
            this.error_txt = 'Error promoting user'
            this.openModal('error')
          })
      }
    },
    demoteUser(id){
      if (this.last_rank){
        return usersAPIService.changeUserRankTo(id, this.last_rank.id, this.$router)
          .then(() => {
            this.selectUser()
          })
          .catch((e) => {
            this.error_txt = 'Error demoting user'
            this.openModal('error')
          })
      }
    },
  }
}
</script>

<style scoped>
i{
  cursor: pointer;
}
</style>
