import JsPDF from 'jspdf';
import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
import {base64RedCert} from "../../Pages/Admin/Images/RedCertificate";
import {base64PrelimCert} from "../../Pages/Admin/Images/PrelimCertificate";
import {base64FoundationCert} from "../../Pages/Admin/Images/FoundationCertificate";
import {base64BlackCert} from "../../Pages/Admin/Images/BlackCertificate";
import FileAPIService from "../../../../servicehandlers/FileHandler";
import ranksAPIService from "../../../../servicehandlers/RanksAPIService";

const fileAPIService = new FileAPIService()
export async function printFoundationCerts(type, downloadToClient = true) {

  const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const date = new Date();
  const doc = new JsPDF({
    orientation: "landscape",
    format: 'letter',
  });
  let empty = true
  let count = 0
  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala.ttf', nyalaBoldBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  const CertImg = new Image();
  let VO = this
  let fileName = this.printName
  if (this.selectedCert){
    fileName = 'Custom ' + this.customCerts[0].user.name
  }

  switch (type){
    case 'red':
      CertImg.src = base64RedCert
      fileName += " Color Belt Certificate"
      break
    case 'prelim':
      CertImg.src = base64PrelimCert
      fileName += " Prelim Certificate"
      break
    case 'foundation':
      CertImg.src = base64FoundationCert
      fileName += " Foundations Certificate"
      break
    case 'black':
      if (this.theme === ''){
        VO.error_txt = "No Theme Entered"
        VO.openModal('error')
        this.loading = false
        return
      }
      CertImg.src = base64BlackCert
      fileName += " Black Belt Certificate"
  }
  if (!this.selectedCert){
    fileName += 's'
  }
  CertImg.onload = async function () {
    let array = VO.givenData
    if (VO.selectedCert){
      array = VO.customCerts
    }
    for (let order of array) {
      let index = array.indexOf(order) + 1
      VO.precentage = (index / array.length) * 100
      VO.precentage = parseFloat((Math.round(VO.precentage * 100) / 100).toFixed(1))

      if (VO.checkSelected(order.id)) {
        empty = false
        count++

        const imgWidth = this.width;
        const imgHeight = this.height;

        const aspectRatio = imgWidth / imgHeight;

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        let renderWidth, renderHeight;
        if (aspectRatio > pageWidth / pageHeight) {
          renderWidth = pageWidth;
          renderHeight = pageWidth / aspectRatio;
        } else {
          renderHeight = pageHeight;
          renderWidth = pageHeight * aspectRatio;
        }

        let x = (pageWidth - renderWidth) / 2;
        let y = (pageHeight - renderHeight) / 2;

        if (type === 'black') {
          doc.addImage(CertImg, 'JPEG', x, y, renderWidth, renderHeight);
          doc.setFont('Parchment', 'normal')
          doc.setFontSize(80)
          let userName = order.user.first_name + " " + order.user.last_name
          if (VO.selectedCert){
            userName = order.user.name
          }
          const xTarget = 155;
          y = 102;
          let textWidth = doc.getTextWidth(userName);
          x = xTarget - (textWidth / 2);
          doc.text(userName, x, y)

          let nextRankName = order.nextRank.name
          if (order.nextRank.is_sub_rank){
            nextRankName = VO.allRanks.find(data => data.id === order.nextRank.head_rank_id).name
          }
          y = 140;
          doc.setFontSize(60)
          textWidth = doc.getTextWidth(nextRankName);
          x = xTarget - (textWidth / 2);
          doc.text(nextRankName, x, y)

          y = 190
          doc.setFontSize(110)
          textWidth = doc.getTextWidth(VO.theme);
          x = (xTarget + 10) - (textWidth / 2);
          doc.text(VO.theme, x, y)

          let monthName = ''
          doc.setFont('lastninja', 'normal')
          if (VO.selectedCert){
            printDate(parseInt(order.date.split('-')[1].replace(/^0/, '')) - 1)
            printYear(order.date.split('-')[0])
          } else {
            printDate(date.getMonth())
            printYear(date.getFullYear().toString())
          }
          function printDate(month){
            switch (month){
              case 0:
                monthName = "January"
                doc.setFontSize(25)
                y = 137
                x = 42.5
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 10
                }
                break
              case 1:
                monthName = "February"
                doc.setFontSize(21)
                y = 135.5
                x = 43
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 9
                }
                break
              case 2:
                monthName = "March"
                doc.setFontSize(34)
                y = 140
                x = 41
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 13.5
                }
                break
              case 3:
                monthName = "April"
                doc.setFontSize(34)
                y = 140
                x = 41
                for (let letter of monthName){
                  if (letter === 'i') x = 44
                  else x = 41
                  doc.text(letter, x, y)
                  y += 13.5
                }
                break
              case 4:
                monthName = "May"
                doc.setFontSize(34)
                y = 140
                x = 41
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 27
                }
                break
              case 5:
                monthName = "June"
                doc.setFontSize(34)
                y = 140
                x = 41
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 18
                }
                break
              case 6:
                monthName = "July"
                doc.setFontSize(34)
                y = 140
                x = 41
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 18
                }
                break
              case 7:
                monthName = "August"
                doc.setFontSize(30)
                y = 136
                x = 42
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 12
                }
                break
              case 8:
                monthName = "September"
                doc.setFontSize(19)
                y = 134
                x = 43.5
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 8
                }
                break
              case 9:
                monthName = "October"
                doc.setFontSize(25)
                y = 137
                x = 42.5
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 10
                }
                break
              case 10:
                monthName = "November"
                doc.setFontSize(21)
                y = 135.5
                x = 43
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 9
                }
                break
              case 11:
                monthName = "December"
                doc.setFontSize(21)
                y = 135.5
                x = 43
                for (let letter of monthName){
                  doc.text(letter, x, y)
                  y += 9
                }
                break
            }
          }
          function printYear(year){
            doc.setFontSize(30)
            y = 145
            x = 55
            for (let letter of year){
              doc.text(letter, x, y)
              y += 15
            }
          }

        }
        else {
          doc.addImage(CertImg, 'JPEG', x, y, renderWidth, renderHeight);
          doc.setFont('nyala', 'normal')
          doc.setFontSize(60)
          let userName = order.user.first_name + " " + order.user.last_name
          if (VO.selectedCert){
            userName = order.user.name
          }
          let xTarget = pageWidth / 2;
          y = 98;
          let textWidth = doc.getTextWidth(userName);
          x = xTarget - (textWidth / 2);

          doc.setFontSize(60)
          const outlineColor = [255, 255, 255];
          const textColor = [0, 0, 0];
          let outline = .7

          drawOutlinedText(userName, x, y, outlineColor, textColor, outline);
          doc.setFontSize(35)
          let rankName = ''
          if (type === 'prelim') {
            rankName = order.rank.name
          } else {
            rankName = order.nextRank.name
          }
          textWidth = doc.getTextWidth(rankName);
          x = xTarget - (textWidth / 2);
          y = 143
          outline = .5
          drawOutlinedText(rankName, x, y, outlineColor, textColor , outline);

          doc.setFontSize(16)
          let dateString = monthsShort[date.getMonth()] + ' ' + date.getFullYear()
          if (VO.selectedCert){
            dateString = monthsShort[parseInt(order.date.split('-')[1].replace(/^0/, '')) - 1] + ' ' + order.date.split('-')[0]
          }
          xTarget = 240
          textWidth = doc.getTextWidth(dateString);
          x = xTarget - (textWidth / 2);
          y = 190
          doc.text(dateString, x, y)

          function drawOutlinedText(text, x, y, outlineColor, textColor, offset) {
            doc.setTextColor(...outlineColor);

            for (let i = offset * -1; i <= offset; i += .1) {
              doc.text(text, x + i, y + offset)
              doc.text(text, x + offset, y + i)
              doc.text(text, x + i, y - offset)
              doc.text(text, x - offset, y + i)
            }

            doc.setTextColor(...textColor);
            doc.text(text, x, y);
          }

        }

        if (count !== VO.selectedData.length) {
          doc.addPage();
        }
      }
    }
    if (!empty) {
      if (downloadToClient){
        doc.save(fileName);
        VO.clearCustomCert()
      } else {
        const pdfData = doc.output('blob');

        let formData = new FormData();
        formData.append('cert_pdf', pdfData, fileName);

        return fileAPIService.uploadFile(formData, this$router)
          .then(response => {
            //console.log("Successful upload: " + response)
          })
          .catch(e => {
            //console.log('Failed upload: ' + e)
          })
      }
    } else {
      VO.error_txt = "No Competitors selected"
      VO.openModal('error')
    }
  }
  CertImg.onerror = function(e) {
    VO.error_txt = type[0].toUpperCase() + type.slice(1) + ' Certificate failed to load: ' + e
    VO.openModal('error')
  }
}
