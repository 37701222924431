import Vue from 'vue';
import Router from 'vue-router';
import { store } from '../store';

import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue'

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import Register from 'src/pages/Dashboard/Pages/Register.vue'
import Lock from 'src/pages/Dashboard/Pages/Lock.vue'
import UserOutline from 'src/pages/Dashboard/Pages/Outline/UserOutline.vue'
import UserOutlineManagement from 'src/pages/Dashboard/Pages/Outline/UserOutlineManagement.vue'
import Verification from 'src/pages/Dashboard/Pages/Verification.vue'
import UserEvents from "src/pages/Dashboard/Pages/Events/UserEvents";
import ResetPassword from "../pages/Dashboard/Pages/ResetPassword";
import StudentPrintDetails from "../pages/Dashboard/Pages/Outline/PrintDetails.vue"
import StudentRankChecklist from "../pages/Dashboard/Pages/Outline/RankChecklist.vue"
import CurriculumCard from "../pages/Dashboard/Pages/Events/CurriculumCard.vue"

// outline pages
import Judging from 'src/pages/Dashboard/Pages/Judging/Judging.vue'

// Admin pages
import JudgeAdmin from 'src/pages/Dashboard/Pages/Admin/JudgeAdmin.vue'
import JudgeStudentView from "src/pages/Dashboard/Pages/Judging/JudgeStudentView";
import UserAdmin from 'src/pages/Dashboard/Pages/Admin/Users/UserAdmin.vue'
import AuthGroupsScopes from 'src/pages/Dashboard/Pages/Admin/Users/AuthGroupsScopesAdmin.vue'
import UserAuthGroups from 'src/pages/Dashboard/Pages/Admin/Users/UserAuthgroupsAdmin.vue'
import OutlineManagement from 'src/pages/Dashboard/Pages/Admin/OutlineManagement.vue'
import OutlineNew from 'src/pages/Dashboard/Pages/Outline/OutlineNew.vue'
import EventAdmin from 'src/pages/Dashboard/Pages/Admin/EventAdmin.vue'
import EventManager from 'src/pages/Dashboard/Pages/Admin/EventManager.vue'
import PoomsaeAdmin from "../pages/Dashboard/Pages/Admin/PoomsaeAdmin.vue";
import BreakingTechniqueAdmin from "../pages/Dashboard/Pages/Admin/BreakingTechniqueAdmin.vue";
import ProgramsAdmin from "../pages/Dashboard/Pages/Admin/ProgramsAdmin.vue";
import RanksAdmin from "../pages/Dashboard/Pages/Admin/RanksAdmin.vue";
import UnitsAdmin from "../pages/Dashboard/Pages/Admin/UnitsAdmin.vue";
import AssignmentsAdmin from "../pages/Dashboard/Pages/Admin/Assignments.vue";
import Terminology from "../pages/Dashboard/Pages/Admin/Terminology.vue";
import Techniques from "../pages/Dashboard/Pages/Admin/Techniques.vue";
import Kicks from "../pages/Dashboard/Pages/Admin/Kicks.vue";
import KickCombos from "../pages/Dashboard/Pages/Admin/KickCombos.vue";
import Documentation from "../pages/Dashboard/Pages/Admin/Documentation.vue";
import SelfDefense from "../pages/Dashboard/Pages/Admin/SelfDefense.vue";
import Motions from "../pages/Dashboard/Pages/Admin/Motions.vue";
import PrintRanks from "../pages/Dashboard/Pages/Admin/PrintRanks.vue";
import StepSparring from "../pages/Dashboard/Pages/Admin/StepSparring.vue";

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
import Panels from 'src/pages/Dashboard/Components/Panels.vue'
const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
import Icons from 'src/pages/Dashboard/Components/Icons.vue'
import Typography from 'src/pages/Dashboard/Components/Typography.vue'
import ForgotPassword from "src/pages/Dashboard/Pages/ForgotPassword";
import Outline from "../pages/Dashboard/Pages/Outline/Outline";

// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: '/pages/outlinemanagement',
      name: 'Outlines',
      component: OutlineManagement
    },

    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}

let adminMenu = {
  path: '/adminmain',
  component: DashboardLayout,
  redirect: '/adminmain/outline',
  children: [
    {
      path: 'users',
      name: 'User Management',
      component: UserAdmin
    },
    {
      path: 'judge',
      name: 'Judge Management',
      component: JudgeAdmin
    },
    {
      path: 'outline',
      name: 'Outline Management',
      component: OutlineManagement
    },
    {
      path: 'authgroups',
      name: 'Authgroup Management',
      component: UserAuthGroups
    },
    {
      path: 'scopes',
      name: 'Scopes Management',
      component: AuthGroupsScopes
    },
    {
      path: 'eventadmin',
      name: 'Event Management',
      component: EventAdmin
    },
    {
      path: 'eventmanager',
      name: 'Event Manager',
      component: EventManager
    },
    {
      path: 'poomsae',
      name: 'Poomsae Manager',
      component: PoomsaeAdmin
    },
    {
      path: 'breakingtechniques',
      name: 'BT Technique Manager',
      component: BreakingTechniqueAdmin
    },
    {
      path: 'programs',
      name: 'Programs Manager',
      component: ProgramsAdmin
    },
    {
      path: 'ranks',
      name: 'Ranks Manager',
      component: RanksAdmin
    },
    {
      path: 'units',
      name: 'Units Manager',
      component: UnitsAdmin
    },
    {
      path: 'motions',
      name: 'Motions',
      component: Motions
    },
    {
      path: 'assignments',
      name: 'Assignments Admin',
      component: AssignmentsAdmin
    },
    {
      path: 'terminology',
      name: 'Terminology',
      component: Terminology
    },
    {
      path: 'techniques',
      name: 'Techniques',
      component: Techniques
    },
    {
      path: 'documentation',
      name: 'Documentation',
      component: Documentation
    },
    {
      path: 'selfdefense',
      name: 'Self Defense',
      component: SelfDefense
    },
    {
      path: 'kicks',
      name: 'Kicks',
      component: Kicks
    },
    {
      path: 'kick_combos',
      name: 'Kick Combos',
      component: KickCombos
    },
    {
      path: 'printranks',
      name: 'Print Ranks',
      component: PrintRanks
    },
    {
      path: 'stepsparring',
      name: 'Step Sparring',
      component: StepSparring
    },
  ]
};

let judgeMenu = {
  path: '/judge',
  component: DashboardLayout,
  redirect: '/judge/student',
  children: [
    {
      name: "Judge View Page",
      path: 'student',
      component: JudgeStudentView
    },
    {
      name: "Judging Page",
      path: 'judgemain',
      component: Judging
    }
  ]
};

let userMenu = {
  path: '/myoutlines',
  component: DashboardLayout,
  redirect: '/myoutlines/useroutline',
  children: [
    {
      name: "My Outlines",
      path: 'useroutline',
      component: UserOutline
    },
    {
      name: "User Outline",
      path: 'useroutline',
      component: OutlineNew,
    }
  ]
};

let resetPassword = {
  path: '/profile',
  component: DashboardLayout,
  redirect: '/profile/resetpassword',
  children: [
    {
      name: "Reset Password",
      path: 'resetpassword',
      component: ResetPassword
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'userevents',
      name: 'Events',
      component: UserEvents
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    },
    {
      path: 'curriculumCard',
      name: 'Curriculum Card',
      component: CurriculumCard
    },
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}
let forgotPage = {
  path: '/forgot',
  name: 'Forgot',
  component: ForgotPassword
}

let verifyPage = {
  path: '/verification',
  name: 'Verification',
  component: Verification
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    redirect: '/admin/overview',
    name: 'main',
  },
  loginPage,
  registerPage,
  verifyPage,
  forgotPage,
  resetPassword,
  adminMenu,
  judgeMenu,
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  userMenu,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'printDetails',
        name: 'Print Details',
        component: StudentPrintDetails
      },
      {
        path: 'rankChecklist',
        name: 'Rank Checklist',
        component: StudentRankChecklist
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  {path: '*', component: NotFound}
]

export const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/register','/verification','/forgot'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.state.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
