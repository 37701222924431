<template>
<div>
  <card v-if="cardUser && cardRank">
    <div :class="[cardUser.poom_dan.name === 'Poom'? 'redBorder': 'blackBorder']">
      <div class="row">
        <div class="col-sm-5 align-content-center text-center">
          <label>Name</label>
          <h2>{{cardUser.first_name}} {{cardUser.last_name}}</h2>
        </div>
        <div class="col-sm-3 align-content-center text-center">
          <label>Current Rank</label>
          <h3 :class="[cardUser.poom_dan.name === 'Poom'? 'redText': null]">{{cardUser.poom_dan.name}} - {{cardRank.name}}</h3>
        </div>
        <div class="col-sm-4 align-content-center text-center">
          <label>Points</label>
          <h3>{{currentPoints}} / {{totalPoints}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="row" style="width: 100%" v-for="category of categories.column1" v-bind:key="category.id">
            <div class="col-sm-12">
              <h4 class="text-center">{{category.name}}</h4>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <el-tabs>
            <el-tab-pane v-for="category of categories" v-bind:key="category.id">
              <span slot="label" :class="category.complete ? 'complete' : 'incomplete'">{{category.name}}</span>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="col-sm-1"></div>
      </div>-->
    </div>
  </card>
  <el-dialog
    center
    title="Error"
    :visible.sync="modals.error">
    <div class="text-center">
      <span>Error: {{ error_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
    </div>
  </el-dialog>
  <el-dialog
    center
    title="Success"
    :visible.sync="modals.success">
    <div class="text-center">
      <span>{{ success_txt }}</span>
      <br>
      <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
    </div>
  </el-dialog>
</div>
</template>

<script>
import crypto from "crypto-js";
import { Dialog, Button, Input, InputNumber, TabPane, Tabs } from 'element-ui';
import UserAPIService from "../../../../servicehandlers/UsersAPIService";
import RanksAPIService from "../../../../servicehandlers/RanksAPIService";
import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";
import KicksAPIService from "../../../../servicehandlers/KicksAPIService";
import KickCombosAPIService from "../../../../servicehandlers/KickCombosAPIService";
import PoomsaeAPIService from "../../../../servicehandlers/PoomsaeAPIService";
import SelfDefenseTechniquesAPIService from "../../../../servicehandlers/SelfDefenseTechniquesAPIService";

const usersAPIService = new UserAPIService();
const ranksAPIService = new RanksAPIService();
const techniqueAPIService = new TechniquesAPIService()
const kicksAPIService = new KicksAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const poomsaesAPIService = new PoomsaeAPIService()
const selfDefencesAPIService = new SelfDefenseTechniquesAPIService()
export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
  },
  name: "CurriculumCard",
  data() {
    return {
      modals: {
        error: false,
        success: false,
      },
      error_txt: '',
      success_txt: '',
      currentUser: null,
      cardUser: null,
      cardRank: null,
      editable: false,
      categories: {
        column1: [
          {
            id: 'general',
            name: 'General Requirements',
            items: [],
          },
          {
            id: 'kicks',
            name: 'Kicking',
            items: [],
          },
          {
            id: 'kick_combos',
            name: 'Kicking Combos',
            items: [],
          },
        ],
        column2: [
          {
            id: 'techniques',
            name: 'Techniques',
            items: [],
          },
          {
            id: 'poomsae',
            name: 'Poomsae',
            items: [],
          },
        ],
        column3: [
          {
            id: 'self_defense',
            name: 'Self Defence',
            items: [],
          },
          {
            id: 'sparring',
            name: 'Step Sparring',
            items: [],
          },
          {
            id: 'breaking',
            name: 'Breaking',
            items: [],
          },
        ],
      },
      currentPoints: 0,
      totalPoints: 0,
    }
  },
  async mounted(){
    //console.clear()
    await this.getCurrentUser()
    await this.getCardRank()
  },
  methods: {
    getCurrentUser(){
      let userID = this.$store.getters.userId
      return usersAPIService.getUserById(userID, this.$router)
        .then(async (response) => {
          const birthday = new Date(crypto.AES.decrypt(response.birthday, process.env.VUE_APP_SECRET_KEY).toString(crypto.enc.Utf8))
          const birthDate = new Date(birthday);
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();
          const monthDifference = today.getMonth() - birthDate.getMonth();
          if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          response.age = age
          this.currentUser = response
          let scopes = this.$store.getters.scopes
          if (scopes.some(data => data.name === 'edit-all-events') && this.$route.params.userId){
            this.editable = true
            this.cardUser = await this.getUserById(this.$route.params.userId)
          } else {
            this.cardUser = this.currentUser
          }
        })
        .catch((e) => {
          this.error_txt = 'Error getting current user: ' + e
          this.openModal('error')
        })
    },
    getUserById(id){
      if (id) {
        return usersAPIService.getUserById(id, this.$router)
          .then(response => {
            return response
          })
          .catch((e) => {
            this.error_txt = 'Error getting card user: ' + e
            this.openModal('error')
          })
      } else {
        this.error_txt = 'No user selected'
        this.openModal('error')
      }
    },
    getCardRank(){
      return ranksAPIService.getRanksById(this.cardUser.rank_id, this.$router)
        .then(response => {
          this.cardRank = response
          this.getRankDetails()
        })
        .catch((e) => {
          this.error_txt = 'Error getting card rank: ' + e
          this.openModal('error')
        })
    },
    async getRankDetails(){
      try {
        this.cardRank.techniques = await techniqueAPIService.getTechniqueByRank(this.cardRank.id, this.$router)
        this.cardRank.kicks = await kicksAPIService.getKickByRank(this.cardRank.id, this.$router)
        this.cardRank.kickCombos = await kickCombosAPIService.getKickCombosByRank(this.cardRank.id, this.$router)
        this.cardRank.poomsae = await poomsaesAPIService.getPoomsaeByRank(this.cardRank.id, this.$router)
        this.cardRank.self_defense = await selfDefencesAPIService.getSelfDefenseTechniquesByRank(this.cardRank.id, this.$router)

        //console.log(this.cardRank)
      } catch (e) {
        this.error_txt = 'Error getting rank details: ' + e
        this.openModal('error')
      }
    },
    getCustomizedCard(){

    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
  },
}
</script>

<style scoped>
h2, h3{
  margin: 0;
}
.redText{
  color: rgb(215, 31, 39);
}
.redBorder{
  border: rgb(215, 31, 39) 3px solid;
}
.blackBorder{
  border: black 3px solid;
}
.complete{
  color: #1e7e34;
}
.incomplete{
  color: rgb(215, 31, 39);
}
</style>
