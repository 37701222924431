import JsPDF from 'jspdf';
import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlineAPIService = new OutlineAPIService();
export async function printBoardCount() {
  let missingOutlines = false
  let outlines = []
  for (let data of this.givenData){
    if (data.outline){
      await outlineAPIService.getOutlineByID(data.outline.id, this.$router)
        .then((outline) => {
          let temp = {
            outline: outline,
            rank: data.rank,
          }
          outlines.push(temp);
        })
        .catch((error) => {
          this.error_txt = 'Error getting outline by id: ' + error;
          this.openModal('error');
        });
    } else {
      this.warning_txt = 'Be aware that one or more of the competitors is missing an outline'
      this.openModal('warning')
      this.loading = false
      missingOutlines = true
    }
  }
  return Promise.all(outlines)
    .then(() => {
      const doc = new JsPDF({
        orientation: "portrait",
      });
      let x = doc.internal.pageSize.getWidth() / 2
      let y = 10

      let columns = ['Count', 'Board Size', 'Tester', 'Technique']

      let boards = {}
      for (let board of this.dropdown_boards.filter(data => data.id !== null)){
        boards[board.id] = {
          name: board.name,
          count: 0,
          boards: []
        }
      }

      for (const data of outlines) {
        this.setTechniqueNames(data.outline);
        for (let breakObj of data.outline.breaking_techniques_to_outlines){
          boards[breakObj.board_id].count += breakObj.num_boards
          boards[breakObj.board_id].boards.push([breakObj.num_boards, boards[breakObj.board_id].name, data.outline.user.first_name + ' ' + data.outline.user.last_name, breakObj.techName])
        }
      }

      let boardList = []

      for (let key of Object.keys(boards)){
        for (let item of boards[key].boards){
          boardList.push(item)
        }
      }

      doc.setFontSize('16');
      doc.text(this.printName + ' Board Count', x, y, 'center')
      y += 7

      doc.setFontSize('14');

      if (missingOutlines){
        doc.setTextColor(255, 0, 0);
        doc.text('Missing one or more Outlines', x, y, 'center')
        doc.setTextColor(0, 0, 0);
        y += 5
      }

      doc.setFontSize('12')

      doc.text('Board Count:', x, y, 'center')
      y += 5

      let addX = ((x * 2) - 30) / Object.keys(boards).length
      x = 30
      for (let key of Object.keys(boards)){
        doc.text(boards[key].name + ': ' + boards[key].count, x, y, 'center')
        x += addX
      }
      y += 5

      doc.autoTable({
        startY: y,
        head: [columns],
        body: boardList,
        styles: {
          lineColor: [0, 0, 0],  // Black borders
          lineWidth: 0.5,        // Slightly thicker border
          fontSize: 10,          // Default font size for table
          font: 'helvetica',     // Helvetica font for the entire table
        },
        headStyles: {
          fillColor: [255, 255, 255],  // Blue background for header
          textColor: [0, 0, 0],// White text in header
          fontSize: 12,              // Larger font for headers
          fontStyle: 'bold',         // Bold text for headers
        },
        bodyStyles: {
          fillColor: [255, 255, 255], // Light gray background for body rows
          textColor: [0, 0, 0],       // Black text in body rows
          lineColor: [0, 0, 0], // Light gray borders for body rows
        },
        alternateRowStyles: {
          fillColor: [255, 255, 255], // White background for alternating rows
        },
        margin: { top: 10 },
      });

      let fileName = this.printName + ' Board Count.pdf'
      doc.save(fileName);
    })


}
