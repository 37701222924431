import JsPDF from 'jspdf';
import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
export async function printCallSheets() {
  let fileName = this.printName
  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  let empty = true
  let count = 0

  await this.getStepSparring()

  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  let left = true

  if (this.printByTypes[this.printType].name === 'Rank') {
    fileName += ' Ranks '
    for (let id of this.selectedData) {
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      let currentRank
      for (let rank of this.allRanks) {
        if (rank.id === id) {
          currentRank = rank
          break
        }
      }
      empty = false
      count++
      doc.setFontSize('10');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let x;
      let y = 10;
      if (left) {
        x = 10
      } else {
        x = halfPage + 5
      }

      function printTopInfo(rank) {
        let textWidth
        let today = new Date()
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        y = 10
        doc.setFont('nyala', 'normal')
        doc.setFontSize('12')
        doc.text(today.toLocaleString('en-US', options), x, y)

        y += 10
        doc.setFont('times', 'normal')
        doc.setFontSize('14');
        let titleString = 'Beyond Sports Taekwondo Curriculum'
        textWidth = doc.getTextWidth(titleString)
        let quartarPage = x + ((halfPage - 10) / 2)
        doc.text(titleString, quartarPage - (textWidth / 2), y);

        y += 10
        textWidth = doc.getTextWidth(rank.name)
        doc.setFont('Times-Roman', 'bolditalic')
        doc.text(rank.name, quartarPage - (textWidth / 2), y)

        y += 10;
      }

      printTopInfo(currentRank)

      for (let category of this.categories) {
        switch (category.id) {
          case "poomsae":
            await this.getPoomsaeByRankId(currentRank.id)
            break
          case "kicks":
            await this.getKicksByRankId(currentRank.id)
            break
          case "self_defense":
            await this.getSelfDefByRankId(currentRank.id)
            break
          case "kick_combos":
            await this.getKickCombosByRankId(currentRank.id)
            break
          case "techniques":
            await this.getTechsByRankId(currentRank.id)
            break
          case "breaking":
            await this.getDefaultOutlineBreaksByRankId(currentRank.id)
            break
          default:
            continue
        }
        if (category.items.length > 0) {
          for (let item of category.items) {
            let printName = ''
            if (item.poom_dan.name !== 'Both'){
              printName = item.poom_dan.name + ' - '
            }
            switch (category.id){
              case 'kicks':
              case 'kick_combos':
                printName += item.description
                break
              case 'breaking':
                printName += item.techName
                break
              default:
                printName += item.name
                break
            }
            if (category.items.indexOf(item) === 0) {
              doc.setFont('Times-Roman', 'bolditalic')
              doc.setFontSize('12')
              doc.text(category.name, x, y)
              doc.line(x, y + 1, x + (halfPage - 15), y + 1);
              y += 7
            }
            doc.setFontSize('12')
            doc.setFont('nyala', 'normal')
            if (doc.getTextWidth(printName) >= halfPage - 30){
              let splitStr = printName.split(' ')
              let builtStr = ''
              let textWidth
              while (splitStr.length !== 0){
                textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
                if (textWidth > halfPage - 30){
                  doc.text(builtStr, x + 15, y)
                  y += 4
                  builtStr = splitStr.shift() + ' '
                } else {
                  builtStr += splitStr.shift() + ' '
                }
              }
              doc.text(builtStr, x + 15, y)
            } else {
              doc.text(printName, x + 15, y)
            }
            y += 7
          }
        }
      }
      this.clearCategories()

      if (count !== this.selectedData.length) {
        if (!left) {
          doc.addPage();
        }
        left = !left
      }
    }
  }
  else if (this.printByTypes[this.printType].name === 'Groups'){
    fileName += ' Groups '
    for (let id of this.selectedData) {
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      let currentType
      for (let type of this.testing_types){
        if (type.id === id){
          currentType = type
          break
        }
      }

      empty = false
      count++

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let newPage = false

      for (let i = 1; i <= 5; i++){

        let emptyGroup = true
        let rankList = []
        let dataArray = this.givenData.filter(e => e.testing_type_id === id).filter(data => data.group.group_id === i)
        dataArray.sort((a, b) => a.rank.order - b.rank.order);
        for (let order of dataArray){
          if (!rankList.includes(order.rank.id)){
            rankList.push(order.rank.id)
          }
        }
        //console.log(rankList)

        for (let id of rankList){
          let currentRank
          for (let rank of this.allRanks){
            if (rank.id === id){
              currentRank = rank
              emptyGroup = false
              break
            }
          }

          let x;
          let y = 10;
          if (left) {
            x = 10
          } else {
            x = halfPage + 5
          }

          if (rankList.indexOf(id) === 0){
            doc.setFont('nyala', 'normal')
            doc.setFontSize('18');
            doc.text(currentType.name + ' - Group ' + i, halfPage, 10, 'center')
          }

          function printTopInfo(rank) {
            let textWidth
            let today = new Date()
            const options = {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            y = 10
            if (left){
              doc.setFont('nyala', 'normal')
              doc.setFontSize('12')
              doc.text(today.toLocaleString('en-US', options), x, y)
            }
            y += 10
            doc.setFont('times', 'normal')
            doc.setFontSize('14');
            let titleString = 'Beyond Sports Taekwondo Curriculum'
            textWidth = doc.getTextWidth(titleString)
            let quartarPage = x + ((halfPage - 10) / 2)
            doc.text(titleString, quartarPage - (textWidth / 2), y);

            y += 10
            textWidth = doc.getTextWidth(rank.name)
            doc.setFont('Times-Roman', 'bolditalic')
            doc.text(rank.name, quartarPage - (textWidth / 2), y)

            y += 10;
          }

          printTopInfo(currentRank)

          for (let category of this.categories) {
            switch (category.id) {
              case "poomsae":
                await this.getPoomsaeByRankId(currentRank.id)
                break
              case "kicks":
                await this.getKicksByRankId(currentRank.id)
                break
              case "self_defense":
                await this.getSelfDefByRankId(currentRank.id)
                break
              case "kick_combos":
                await this.getKickCombosByRankId(currentRank.id)
                break
              case "techniques":
                await this.getTechsByRankId(currentRank.id)
                break
              case "breaking":
                await this.getDefaultOutlineBreaksByRankId(currentRank.id)
                break
              default:
                continue
            }
            if (category.items.length > 0) {
              for (let item of category.items) {
                let printName = ''
                if (item.poom_dan.name !== 'Both'){
                  printName = item.poom_dan.name + ' - '
                }
                switch (category.id){
                  case 'kicks':
                  case 'kick_combos':
                    printName += item.description
                    break
                  case 'breaking':
                    printName += item.techName
                    break
                  default:
                    printName += item.name
                    break
                }
                if (category.items.indexOf(item) === 0) {
                  doc.setFont('Times-Roman', 'bolditalic')
                  doc.setFontSize('12')
                  doc.text(category.name, x, y)
                  doc.line(x, y + 1, x + (halfPage - 15), y + 1);
                  y += 7
                }
                doc.setFontSize('12')
                doc.setFont('nyala', 'normal')
                if (doc.getTextWidth(printName) >= halfPage - 30){
                  let splitStr = printName.split(' ')
                  let builtStr = ''
                  let textWidth
                  while (splitStr.length !== 0){
                    textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
                    if (textWidth > halfPage - 30){
                      doc.text(builtStr, x + 15, y)
                      y += 4
                      builtStr = splitStr.shift() + ' '
                    } else {
                      builtStr += splitStr.shift() + ' '
                    }
                  }
                  doc.text(builtStr, x + 15, y)
                } else {
                  doc.text(printName, x + 15, y)
                }
                y += 7
              }
            }
          }
          this.clearCategories()
          newPage = false

          if (rankList.indexOf(id) !== rankList.length - 1){
            left = !left
            if (left && !newPage){
              //console.log('Rank New Page')
              doc.addPage()
              newPage = true
            }
          }
        }
        if  (i !== 5 ){
          left = true
          if (!newPage){
            //console.log('Group New Page')
            doc.addPage()
            newPage = true
          }
        }
      }
      if (count !== this.selectedData.length){
        left = true
        if (!newPage){
          //console.log('Test New Page')
          doc.addPage()
          newPage = true
        }
      }
    }
    let pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount)
  }
  else if (this.printByTypes[this.printType].name === 'Tests'){
    fileName += ' Tests '
    for (let id of this.selectedData) {
      let index = this.selectedData.indexOf(id) + 1
      this.precentage = (index / this.selectedData.length) * 100
      this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

      let currentType
      for (let type of this.testing_types){
        if (type.id === id){
          currentType = type
          break
        }
      }

      empty = false
      count++

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const halfPage = pageWidth / 2;
      let newPage = false

      let rankList = []
      let dataArray = this.givenData.filter(e => e.testing_type_id === id)
      dataArray.sort((a, b) => a.rank.order - b.rank.order);
      for (let order of dataArray){
        if (!rankList.includes(order.rank.id)){
          rankList.push(order.rank.id)
        }
      }
      //console.log(rankList)

      for (let id of rankList){
        let currentRank
        for (let rank of this.allRanks){
          if (rank.id === id){
            currentRank = rank
            break
          }
        }

        let x;
        let y = 10;
        if (left) {
          x = 10
        } else {
          x = halfPage + 5
        }

        if (rankList.indexOf(id) === 0){
          doc.setFont('nyala', 'normal')
          doc.setFontSize('18');
          doc.text(currentType.name, halfPage, 10, 'center')
        }

        function printTopInfo(rank) {
          let textWidth
          let today = new Date()
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          y = 10
          if (left){
            doc.setFont('nyala', 'normal')
            doc.setFontSize('12')
            doc.text(today.toLocaleString('en-US', options), x, y)
          }

          y += 10
          doc.setFont('times', 'normal')
          doc.setFontSize('14');
          let titleString = 'Beyond Sports Taekwondo Curriculum'
          textWidth = doc.getTextWidth(titleString)
          let quartarPage = x + ((halfPage - 10) / 2)
          doc.text(titleString, quartarPage - (textWidth / 2), y);

          y += 10
          textWidth = doc.getTextWidth(rank.name)
          doc.setFont('Times-Roman', 'bolditalic')
          doc.text(rank.name, quartarPage - (textWidth / 2), y)

          y += 10;
        }

        printTopInfo(currentRank)

        for (let category of this.categories) {
          switch (category.id) {
            case "poomsae":
              await this.getPoomsaeByRankId(currentRank.id)
              break
            case "kicks":
              await this.getKicksByRankId(currentRank.id)
              break
            case "self_defense":
              await this.getSelfDefByRankId(currentRank.id)
              break
            case "kick_combos":
              await this.getKickCombosByRankId(currentRank.id)
              break
            case "techniques":
              await this.getTechsByRankId(currentRank.id)
              break
            case "breaking":
              await this.getDefaultOutlineBreaksByRankId(currentRank.id)
              break
            default:
              continue
          }
          if (category.items.length > 0) {
            for (let item of category.items) {
              let printName = ''
              if (item.poom_dan.name !== 'Both'){
                printName = item.poom_dan.name + ' - '
              }
              switch (category.id){
                case 'kicks':
                case 'kick_combos':
                  printName += item.description
                  break
                case 'breaking':
                  printName += item.techName
                  break
                default:
                  printName += item.name
                  break
              }
              if (category.items.indexOf(item) === 0) {
                doc.setFont('Times-Roman', 'bolditalic')
                doc.setFontSize('12')
                doc.text(category.name, x, y)
                doc.line(x, y + 1, x + (halfPage - 15), y + 1);
                y += 7
              }
              doc.setFontSize('12')
              doc.setFont('nyala', 'normal')
              if (doc.getTextWidth(printName) >= halfPage - 30){
                let splitStr = printName.split(' ')
                let builtStr = ''
                let textWidth
                while (splitStr.length !== 0){
                  textWidth = doc.getTextWidth(builtStr + splitStr[0] + ' ')
                  if (textWidth > halfPage - 30){
                    doc.text(builtStr, x + 15, y)
                    y += 4
                    builtStr = splitStr.shift() + ' '
                  } else {
                    builtStr += splitStr.shift() + ' '
                  }
                }
                doc.text(builtStr, x + 15, y)
              } else {
                doc.text(printName, x + 15, y)
              }
              y += 7
            }
          }
        }
        this.clearCategories()
        newPage = false

        if (rankList.indexOf(id) !== rankList.length - 1){
          left = !left
          if (left && !newPage){
            //console.log('Rank New Page')
            doc.addPage()
            newPage = true
          }
        }
      }
      if (count !== this.selectedData.length){
        left = true
        if (!newPage){
          //console.log('Test New Page')
          doc.addPage()
        }
      }
    }
    /*let pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount)*/
  }

  if (!empty) {
    fileName += "Call Sheets.pdf"
    doc.save(fileName);
  } else {
    this.error_txt = "No Ranks to Print"
    this.openModal('error')
  }
}
