export async function calculateScoresForOutline(outline) {
  // storage objects to hold values in prior to calculating scores
  const scores_objects = {};
  scores_objects.pre_final_technical_score = 0;
  scores_objects.final_technical_score = 0;
  scores_objects.final_technical_score_length = 0;
  scores_objects.total_line_scores = 0;
  scores_objects.avg_total_line_scores = 0;
  scores_objects.final_score = 0;
  scores_objects.total_boards = 0;
  scores_objects.total_speed_breaks = 0;
  scores_objects.userClass = {
    value: 6,
    avgBrdSize: {},
  };
  scores_objects.presentation = 0;
  scores_objects.average_board_size = 0;
  scores_objects.boards_below_avg = 0
  scores_objects.boards_above_avg = 0
  let VO = this
  const userClass = setUserClass(outline.weight, outline.user);
  scores_objects.userClass = userClass;
  const presentation = setPresentation(outline);
  const board_objects = setBoards(outline, scores_objects);
  const board_size_objects = setBoardSizeObjects(outline);
  const support_option_objects = setSupportOptions(outline, scores_objects);
  const technique_objects = setTechniqueSelections(outline);
  const direction_objects = setDirectionSelections(outline);
  const modifier_objects = setModifiers(outline);
  const penalties_objects = setPenalties(outline);

  let count = 0
  let total = 0

  // first setup a scores obj we'll send back
  const outline_techniques = outline.breaking_techniques_to_outlines
  /*console.log(outline)*/
  for (const technique_to_outline of outline_techniques) {
    count++
    // calculate each line of technique outlines
    const id = technique_to_outline.id;
    scores_objects[id] = {};
    scores_objects[id] = {
      a_minus: 0,
      starting_line_score: 0,
      base_point_value: 0,
      average_board_size: 0,
      additional_points: 0,
      line_score: 0,
      penalties: 0,
      total_score: 0,
      pre_final_technical_score: 0,
      final_technical_score: 0,
      attempts: 0,
      presentation: presentation,
    };
    // initializers
    scores_objects[id].average_board_size = userClass.value - technique_objects[id].a_minus;
    total += scores_objects[id].average_board_size
    scores_objects[id].starting_line_score = technique_objects[id].base_point_value +
      board_size_objects[id].order - scores_objects[id].average_board_size;

    // first, get all the a-minuses
    // this.scores_objects[id].a_minus = this.userClass - this.board_size_objects[id].order;
    scores_objects[id].a_minus += support_option_objects[id].a_minus;
    // this.scores_objects[id].a_minus += this.technique_objects[id].a_minus;
    scores_objects[id].a_minus += direction_objects[id].a_minus;
    const modifiers = modifier_objects[id];
    for (const modifier of modifiers) {
      if (modifier.a_minus) {
        scores_objects[id].a_minus += modifier.a_minus;
      }
    }

    // Build the possible score calculations now
    // this.scores_objects[id].possible_score = this.technique_objects[id].base_point_value;
    scores_objects[id].additional_points += (board_objects[id].additional_boards * 2);
    scores_objects[id].additional_points += support_option_objects[id].point_value;
    scores_objects[id].additional_points += direction_objects[id].additional_point_value;

    let no_spacers = false;
    for (const modifier of modifiers) {
      if (modifier.name === 'no_spacers') {
        no_spacers = true;
        scores_objects[id].additional_points += (board_objects[id].additional_boards * 1);
      } else {
        if (modifier.point_value) {
          scores_objects[id].additional_points += modifier.point_value;
        }
      }
    }

    let partial_break = false;
    let drop_board = false;
    let no_break = false;
    const penaltyObj = penalties_objects[id];
    for (const penalty of penaltyObj) {
      let attempts = 0;
      if (penalty.name === 'attempts') {
        attempts += penalty.attempts;
        scores_objects[id].penalties += (attempts);
      }
      if (penalty.name === 'changed_break') {
        scores_objects[id].penalties += penalty.point_value;
      }
      if (penalty.name === 'partial_break') {
        partial_break = true;
      }
      if (penalty.name === 'no_break') {
        no_break = true;
      }
      if (penalty.name === 'dropped_board') {
        drop_board = true;
      }
    }

    // now calculate the possible score
    scores_objects[id].line_score = scores_objects[id].starting_line_score +
      scores_objects[id].a_minus + scores_objects[id].additional_points;
    scores_objects.total_line_scores += scores_objects[id].line_score;
    scores_objects.total_average_score = ((scores_objects.total_line_scores
      / outline_techniques.length) || 0).toFixed(2);

    // now calculate the possible score
    scores_objects[id].total_score = scores_objects[id].line_score;

    if (no_break) {
      scores_objects[id].total_score = 0;
      scores_objects[id].penalties += scores_objects[id].line_score;
    }

    if (drop_board) {
      // for each board dropped it will give 2 points of penalty
      if (no_spacers) {
        scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
        scores_objects[id].penalties += parseInt(technique_to_outline.dropped_a_board);
      } else {
        scores_objects[id].penalties += (parseInt(technique_to_outline.dropped_a_board) * 2);
      }
    }

    if (partial_break) {
      scores_objects[id].penalties += scores_objects[id].line_score / 2;
    }

    // now calculate the total scores for each line
    scores_objects[id].total_score = scores_objects[id].line_score - scores_objects[id].penalties;
    if (scores_objects[id].total_score < 0) {
      scores_objects[id].total_score = 0;
    }
    scores_objects.final_technical_score_length += 1;
    scores_objects.pre_final_technical_score += scores_objects[id].total_score;
    scores_objects.presentation = scores_objects[id].presentation
  }

  // calculate the technical score
  if (scores_objects.final_technical_score_length > 0) {
    scores_objects.final_technical_score = (scores_objects.pre_final_technical_score /
      scores_objects.final_technical_score_length);
  } else {
    scores_objects.final_technical_score = 0;
  }

  scores_objects.average_board_size = total / count

  // calculate the avg of the total line scores
  if (scores_objects.final_technical_score_length > 0) {
    scores_objects.avg_total_line_scores = (scores_objects.total_line_scores /
      scores_objects.final_technical_score_length);
  } else {
    scores_objects.avg_total_line_scores = 0;
  }

  scores_objects.final_score = scores_objects.final_technical_score + scores_objects.presentation;
  setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects);
  return scores_objects;

  function setUserClass(weight, user) {
    let userClass = {
      value: 6,
      avgBrdSize: {},
    };
    if (!weight) {
      weight = parseFloat(user.weight);
    }
    if (weight >= 35 && weight <= 49.9) {
      userClass.value = 1;
      userClass.avgBrdSize = VO.dropdown_boards[1]
    } else if (weight >= 50 && weight <= 70.5) {
      userClass.value = 2;
      userClass.avgBrdSize = VO.dropdown_boards[2]
    } else if (weight >= 70.6 && weight <= 96.6) {
      userClass.value = 3;
      userClass.avgBrdSize = VO.dropdown_boards[3]
    } else if (weight >= 96.7 && weight <= 127.5) {
      userClass.value = 4;
      userClass.avgBrdSize = VO.dropdown_boards[4]
    } else if (weight >= 127.6 && weight <= 164.9) {
      userClass.value = 5;
      userClass.avgBrdSize = VO.dropdown_boards[5]
    } else if (weight >= 165) {
      userClass.value = 6;
      userClass.avgBrdSize = VO.dropdown_boards[6]
    } else {
      userClass.value = 6;
      userClass.avgBrdSize = VO.dropdown_boards[6]
    }
    return userClass;
  }
  function setPresentation(outline) {
    let presentation = 0;
    let judgeScores = [];
    let allJudges = true;
    const j1 = parseFloat(outline.j1);
    const j2 = parseFloat(outline.j2);
    const j3 = parseFloat(outline.j3);
    const j4 = parseFloat(outline.j4);
    const j5 = parseFloat(outline.j5);

    if (j1 > 0) {
      judgeScores.push(j1);
    } else {
      allJudges = false;
    }
    if (j2 > 0) {
      judgeScores.push(j2);
    } else {
      allJudges = false;
    }
    if (j3 > 0) {
      judgeScores.push(j3);
    } else {
      allJudges = false;
    }
    if (j4 > 0) {
      judgeScores.push(j4);
    } else {
      allJudges = false;
    }
    if (j5 > 0) {
      judgeScores.push(j5);
    } else {
      allJudges = false;
    }

    if (allJudges) {
      const min = Math.min(...judgeScores);
      const max = Math.max(...judgeScores);
      let min_dropped = false;
      let max_dropped = false;
      for (let i = 0; i < judgeScores.length; i += 1) {
        let judge_score = judgeScores[i];
        if (min === judge_score && !min_dropped) {
          judgeScores.splice(i, 1);
          min_dropped = true;
        }
        if (max === judge_score && !max_dropped) {
          judgeScores.splice(i, 1);
          max_dropped = true;
        }
      }
    }
    const sum = judgeScores.reduce((a, b) => a + b, 0);
    presentation = (sum / judgeScores.length) || 0;
    return presentation;
  }
  function setBoards(outline, scores_object) {
    const board_objects = {};
    // loop through each of the technique to outlines
    // figure out how many boards we have, add them together
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        if (technique_to_outline.breaking_technique) {
          board_objects[technique_to_outline.id] = {};
          board_objects[technique_to_outline.id].additional_boards = 0;
          const technique = technique_to_outline.breaking_technique;
          const num_boards = parseInt(technique_to_outline.num_boards);
          const board_multiplier = parseInt(technique.board_multiplier);
          board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
          scores_object.total_boards += num_boards;
        }
      }
    }
    return board_objects;
  }
  function setBoardSizeObjects(outline) {
    const board_size_objects = {};
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        const id = technique_to_outline.id;
        board_size_objects[id] = {};
        board_size_objects[id] = {
          order: 1,
          name: '',
        };
        const board_id = technique_to_outline.board_id;
        for (const board of VO.dropdown_boards) {
          if (board_id === board.id) {
            board_size_objects[id] = {
              order: board.order,
              name: board.name,
            };
          }
        }
      }
    }
    return board_size_objects;
  }
  function setSupportOptions(outline, scores_objects) {
    let support_option_objects = {};
    // loop through the modifiers
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        technique_to_outline.speed_break_color = 'col-sm-2 text-center';
        const dropdown_modifier_id = technique_to_outline.dropdown_modifier_id;
        if (technique_to_outline.breaking_technique) {
          const id = technique_to_outline.id;
          support_option_objects[id] = {};
          support_option_objects[id] = {
            a_minus: 0,
            point_value: 0,
          };
          for (const modifier of VO.dropdown_support_options) {
            if (modifier && (modifier.id === dropdown_modifier_id)) {
              support_option_objects[id].a_minus = modifier.a_minus;
              support_option_objects[id].point_value = modifier.point_value;
            }
            const support_option_id = technique_to_outline.dropdown_modifier_id;
            if (modifier.speed_break && modifier.id === support_option_id) {
              technique_to_outline.speed_break = modifier.speed_break;
              scores_objects.total_speed_breaks += 1;
            }
          }
        }
      }
    }
    return support_option_objects;
  }
  function setTechniqueSelections(outline) {
    const technique_objects = {};
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        const id = technique_to_outline.id;
        if (technique_to_outline.breaking_technique_id && technique_to_outline.breaking_technique) {
          const technique = technique_to_outline.breaking_technique;
          const base_point_value = parseInt(technique.base_point_value);
          const board_multiplier = parseInt(technique.board_multiplier);
          const a_minus = parseInt(technique.a_minus);
          const isPowerBreak = technique.is_power_break;
          technique_objects[id] = {};
          technique_objects[id] = {
            'base_point_value': base_point_value,
            'board_multiplier': board_multiplier,
            'a_minus': a_minus,
            'is_power_break': isPowerBreak,
          };
        } else {
          technique_objects[id] = {
            'base_point_value': 0,
            'board_multiplier': 0,
            'a_minus': 0,
            'is_power_break': false,
          }
        }
      }
    }
    return technique_objects;
  }
  function setDirectionSelections(outline) {
    const direction_objects = {};
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        const id = technique_to_outline.id;
        const direction_id = technique_to_outline.direction_id;
        direction_objects[id] = {};
        direction_objects[id] = {
          'direction_id': direction_id,
          'a_minus': 0,
          'additional_point_value': 0
        };
        if (direction_id) {
          for (const direction of VO.allDirections) {
            if (direction_id === direction.id) {
              const a_minus = parseInt(direction.a_minus);
              const additional_point_value = parseInt(direction.additional_point_value);
              direction_objects[id] = {
                'direction_id': direction_id,
                'a_minus': a_minus,
                'additional_point_value': additional_point_value
              };
            }
          }
        }
      }
    }
    return direction_objects;
  }
  function setModifiers(outline) {
    const modifier_objects = {};
    let successive_mods = 0;
    // sets each modifier in the objects
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        const id = technique_to_outline.id;
        // set the modifiers to nothing for starting out
        modifier_objects[id] = [];

        if (technique_to_outline.blindfolded) {
          technique_to_outline.speed_break = false;
          for (const modifier of VO.checkbox_modifiers) {

            if (modifier && (modifier.name === 'blindfolded')) {
              const a_minus = parseInt(modifier.a_minus);
              const point_value = parseInt(modifier.point_value);
              const name = modifier.description;
              const mod_item = {
                'a_minus': a_minus,
                'point_value': point_value,
                'name': name,
              };
              modifier_objects[id].push(mod_item);
            }
          }
        }
        if (technique_to_outline.self_held) {
          for (const modifier of VO.checkbox_modifiers) {
            if (modifier && (modifier.name === 'self_held')) {
              const a_minus = parseInt(modifier.a_minus);
              const point_value = parseInt(modifier.point_value);
              const name = modifier.description;
              const mod_item = {
                'a_minus': a_minus,
                'point_value': point_value,
                'name': name,
              };
              modifier_objects[id].push(mod_item);
            }
          }
        }
        if (technique_to_outline.simultaneous) {
          for (const modifier of VO.checkbox_modifiers) {
            if (modifier && (modifier.name === 'simultaneous')) {
              const a_minus = parseInt(modifier.a_minus);
              const point_value = parseInt(modifier.point_value);
              const name = modifier.description;
              const mod_item = {
                'a_minus': a_minus,
                'point_value': point_value,
                'name': name,
              };
              modifier_objects[id].push(mod_item);
            }
          }
        }

        // if marked successive, give them 1 point per mark
        if (technique_to_outline.successive) {
          for (const modifier of VO.checkbox_modifiers) {
            if (modifier && (modifier.name === 'successive')) {
              /*successive_mods += 1;
              if (successive_mods === 1) {*/
              const mod_item = {
                'name': modifier.description,
                'a_minus': 2,
                'point_value': 0,
              };
              // technique_item.board_plus_minus += 2;
              modifier_objects[id].push(mod_item);
              /*} else if (successive_mods === 2) {
                const mod_item = {
                  'name': modifier.description,
                  'a_minus': 2,
                  'point_value': 1,
                };
                modifier_objects[id].push(mod_item);
              } else if (successive_mods >= 3) {
                const mod_item = {
                  'name': modifier.description,
                  'a_minus': 2,
                  'point_value': 3,
                };
                modifier_objects[id].push(mod_item);
              }*/
            }
          }
        }
        if (technique_to_outline.no_spacers) {
          for (const modifier of VO.checkbox_modifiers) {
            if (modifier && (modifier.name === 'no_spacers')) {
              const a_minus = parseInt(modifier.a_minus);
              const point_value = parseInt(modifier.point_value);
              const name = modifier.name;
              const mod_item = {
                'a_minus': a_minus,
                'point_value': point_value,
                'name': name,
              };
              modifier_objects[id].push(mod_item);
            }
          }
        }
      }
    }
    return modifier_objects;
  }
  function setPenalties(outline) {
    const penalties_objects = {};
    if (outline && outline.breaking_techniques_to_outlines) {
      for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
        const id = technique_to_outline.id;
        penalties_objects[id] = [];
        if (technique_to_outline.attempts >= 1) {
          for (const penalty of VO.penalties) {
            if (penalty && (penalty.name === 'attempts')) {
              const penalty_point_value = parseInt(penalty.point_value);
              const attempts = parseInt(technique_to_outline.attempts);
              const tmpPenalty = {
                point_value: penalty_point_value,
                attempts,
                name: penalty.name,
              };
              penalties_objects[id].push(tmpPenalty);
            }
          }
        }

        if (technique_to_outline.changed_break) {
          for (const penalty of VO.penalties) {
            if (penalty && (penalty.name === 'changed_break')) {
              const penalty_point_value = parseInt(penalty.point_value);
              const tmpPenalty = {
                point_value: penalty_point_value,
                name: penalty.name,
              };
              penalties_objects[id].push(tmpPenalty);
            }
          }
        }

        if (parseInt(technique_to_outline.dropped_a_board) > 0) {
          for (const penalty of VO.penalties) {
            if (penalty && (penalty.name === 'dropped_board')) {
              const penalty_point_value = parseInt(penalty.point_value);
              const tmpPenalty = {
                point_value: penalty_point_value,
                name: penalty.name,
              };
              penalties_objects[id].push(tmpPenalty);
            }
          }
        }

        if (technique_to_outline.partial_break) {
          for (const penalty of VO.penalties) {
            if (penalty && (penalty.name === 'partial_break')) {
              const penalty_point_value = parseInt(penalty.point_value);
              const tmpPenalty = {
                point_value: penalty_point_value,
                name: penalty.name,
              };
              penalties_objects[id].push(tmpPenalty);
            }
          }
        }
        if (technique_to_outline.didnt_break) {
          for (const penalty of VO.penalties) {
            if (penalty && (penalty.name === 'no_break')) {
              const penalty_point_value = parseInt(penalty.point_value);
              const tmpPenalty = {
                point_value: penalty_point_value,
                name: penalty.name,
              };
              penalties_objects[id].push(tmpPenalty);
            }
          }
        }
      }
    }
    return penalties_objects;
  }
  function setBBTReqs(outline, scores_objects, userClass, board_objects, technique_objects) {
    scores_objects.total_power_breaks = 0;
    for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
      let technique_id = null;
      if (technique_to_outline.breaking_technique_id) {
        technique_id = technique_to_outline.breaking_technique_id;
      }
      let board_id = null;
      if (technique_to_outline.board_id) {
        board_id = technique_to_outline.board_id;
      }
      // first get the board size of the technique_to_outline
      let board_size = 0;
      for (const board of VO.dropdown_boards) {
        if (board.id === board_id) {
          board_size = parseInt(board.order);
        }
      }

      let possible_power_break = false;
      for (const technique of VO.dropdown_techniques) {
        if (technique_id === technique.id) {
          if (technique.is_power_break) {
            possible_power_break = true;
          }
        }
      }

      // this.board_objects[technique_to_outline.id].additional_boards = (num_boards / board_multiplier) - 1;
      // order - weight class is if it's a power break or not
      if (possible_power_break) {
        let power_break_num = board_size - userClass.value;
        if (power_break_num >= 2) {
          // yay, power break!
          scores_objects.total_power_breaks += 1;
          if (power_break_num >= 3){
            scores_objects.boards_above_avg += 1
            technique_to_outline.color = 'text-center border border-danger';
          } else {
            technique_to_outline.color = 'text-center border border-primary';
          }
        } else if (power_break_num >= 0) {
          technique_to_outline.color = 'col-sm-1 text-center';
          if (board_objects[technique_to_outline.id].additional_boards >= 1) {
            scores_objects.total_power_breaks += 1;
            technique_to_outline.color = 'col-sm-1 text-center border border-primary';
          }
        } else if (power_break_num < 0) {
          power_break_num += (scores_objects[technique_to_outline.id].a_minus +
            technique_objects[technique_to_outline.id].a_minus);
          if (power_break_num < 0) {
            scores_objects.boards_below_avg += 1
            technique_to_outline.color = 'col-sm-1 text-center border border-warning';
          } else {
            technique_to_outline.color = 'col-sm-1 text-center';
          }
        } else {
          technique_to_outline.color = 'col-sm-1 text-center';
        }
      }
    }
  }
}
