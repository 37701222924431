import JsPDF from 'jspdf';
import moment from 'moment';
import PoomsaeAPIService from "../../../../servicehandlers/PoomsaeAPIService";
import KicksAPIService from "../../../../servicehandlers/KicksAPIService";
import SelfDefenseTechniquesAPIService from "../../../../servicehandlers/SelfDefenseTechniquesAPIService";
import KickCombosAPIService from "../../../../servicehandlers/KickCombosAPIService";
import TechniquesAPIService from "../../../../servicehandlers/TechniquesAPIService";
import BreakingTechniquesAPIService from "../../../../servicehandlers/BreakingTechniquesAPIService";

const poomsaeAPIService = new PoomsaeAPIService()
const kicksAPIService = new KicksAPIService()
const selfDefenseTechniquesAPIService = new SelfDefenseTechniquesAPIService()
const kickCombosAPIService = new KickCombosAPIService()
const techniqueAPIService = new TechniquesAPIService()
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();
export async function printCurriculum() {

  // get kicks and motions for the kick combos
  let all_kicks = null;
  try {
    all_kicks = await kicksAPIService.getKick(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting kicks: ' + e;
    this.openModal('error');
    return;
  }
  all_kicks.sort((a, b) => {return a.poom_dan.id - b.poom_dan.id;});
  let all_kick_combos = null;
  try {
    all_kick_combos = await kickCombosAPIService.getKickCombos(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting kick combos: ' + e;
    this.openModal('error');
    return;
  }
  all_kick_combos.sort((a, b) => {return a.poom_dan.id - b.poom_dan.id;});
  let all_poomsae = null;
  try {
    all_poomsae = await poomsaeAPIService.getPoomsae(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting poomsae: ' + e;
    this.openModal('error');
    return;
  }
  all_poomsae.sort((a, b) => {return a.poom_dan.id - b.poom_dan.id;});
  let all_techs = null;
  try {
    all_techs = await techniqueAPIService.getTechnique(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting techniques: ' + e;
    this.openModal('error');
    return;
  }
  all_techs.sort((a, b) => {return a.poom_dan.id - b.poom_dan.id;});
  let all_self_defs = null;
  try {
    all_self_defs = await selfDefenseTechniquesAPIService.getSelfDefenseTechniques(this.$router)
  } catch (e) {
    this.error_txt = 'Error getting self defense: ' + e;
    this.openModal('error');
    return;
  }
  all_self_defs.sort((a, b) => {return a.poom_dan.id - b.poom_dan.id;});

  const date = moment().format('MM-DD-YYYY');
  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  doc.setFontSize('12');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const halfPage = pageWidth / 2;

  let fileName = ''
  let endOfFileName = ''
  let alreadyPrinted = []

  for (let id of this.selectedData){
    for (let order of this.givenData){
      if (id === order.id && !alreadyPrinted.includes(id)){
        alreadyPrinted.push(id)
        let index = this.selectedData.indexOf(order.id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        let rank
        if (this.printCurrent){
          rank = order.rank
          endOfFileName = ' Current Curriculum.pdf'
        } else {
          rank = order.nextRank
          endOfFileName = ' Next Curriculum.pdf'
        }
        if (this.type === 'rank-curriculum'){
          rank = order
          endOfFileName = ' Curriculum.pdf'
        }
        if (!rank){
          continue
        }

        let x = 10;
        let y = 5;
        doc.text(date, x, y);
        y += 7;
        doc.setFontSize('12');
        doc.text('Beyond Sports Taekwondo Curriculum', x, y);
        y += 7;

        doc.setFontSize('12');
        doc.text(rank.name, x, y);
        doc.setFontSize('8');
        y += 7;

        doc.setFontSize('12');
        doc.text('Kicking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let rank_kicks = null;
        try {
          rank_kicks = await kicksAPIService.getKickByRank(rank.id, this.$router)
          const idIndexMap = Object.fromEntries(
            all_kicks.map((item, index) => [item.id, index])
          );
          rank_kicks.sort((a, b) => idIndexMap[a.kick_id] - idIndexMap[b.kick_id]);
          for (let item of rank_kicks){
            for (let kick of all_kicks){
              if (kick.id === item.kick_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                if (kick.description){
                  let printName = ''
                  if (kick.poom_dan.name !== 'Both'){
                    printName = kick.poom_dan.name + ' - '
                  }
                  printName +=  kick.description
                  doc.text(printName, x + 5, y);
                }
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Error getting kicks by userRank: ' + e;
          this.openModal('error');
          return;
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Kicking Combos', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let rank_kick_combos = null;
        try {
          rank_kick_combos = await kickCombosAPIService.getKickCombosByRank(rank.id, this.$router)
          const idIndexMap = Object.fromEntries(
            all_kick_combos.map((item, index) => [item.id, index])
          );
          rank_kick_combos.sort((a, b) => idIndexMap[a.kick_combo_id] - idIndexMap[b.kick_combo_id]);
          for (let item of rank_kick_combos){
            for (let kick_combo of all_kick_combos){
              if (kick_combo.id === item.kick_combo_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                if (kick_combo.description){
                  let printName = ''
                  if (kick_combo.poom_dan.name !== 'Both'){
                    printName = kick_combo.poom_dan.name + ' - '
                  }
                  printName +=  kick_combo.description
                  doc.text(printName, x + 5, y);
                }
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Error getting kick combos: ' + e;
          this.openModal('error');
          return;
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Poomsae', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;

        let poomsae_by_rank = null;
        try {
          poomsae_by_rank = await poomsaeAPIService.getPoomsaeByRank(rank.id, this.$router);
          const idIndexMap = Object.fromEntries(
            all_poomsae.map((item, index) => [item.id, index])
          );
          poomsae_by_rank.sort((a, b) => idIndexMap[a.poomsae_id] - idIndexMap[b.poomsae_id]);
          for (let item of poomsae_by_rank){
            for (let poomsae of all_poomsae){
              if (poomsae.id === item.poomsae_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                let printName = ''
                if (poomsae.poom_dan.name !== 'Both'){
                  printName = poomsae.poom_dan.name + ' - '
                }
                printName +=  poomsae.description
                if (poomsae_by_rank.indexOf(item) === 0) {
                  if (poomsae.description) {
                    doc.text('Current: ' + printName, x + 5, y);
                  }
                  y += 5;
                } else {
                  if (poomsae.description) {
                    doc.text('Previous: ' + printName, x + 5, y);
                  }
                  y += 5;
                }
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting poomsae to ranks: ';
          this.openModal('error');
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Techniques', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let techniques_by_rank = null;
        try {
          techniques_by_rank = await techniqueAPIService.getTechniqueByRank(rank.id, this.$router);
          const idIndexMap = Object.fromEntries(
            all_techs.map((item, index) => [item.id, index])
          );
          techniques_by_rank.sort((a, b) => idIndexMap[a.technique_id] - idIndexMap[b.technique_id]);
          for (let item of techniques_by_rank){
            for (let tech of all_techs){
              if (tech.id === item.technique_id){
                if (y > (pageHeight - 10)){
                  doc.addPage()
                }
                if (tech.description) {
                  let printName = ''
                  if (tech.poom_dan.name !== 'Both'){
                    printName = tech.poom_dan.name + ' - '
                  }
                  printName +=  tech.description
                  doc.text(printName, x + 5, y);
                }
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting techniques to ranks: ';
          this.openModal('error');
        }

        if (this.stepSparring.pdf){
          doc.setFontSize('10')
          doc.text('Step Sparring File: ', x + 2, y)
          y += 5
          doc.setFontSize('8')
          doc.text(this.stepSparring.pdf, x + 5, y)
          y += 5
        } else {
          this.error_txt = "Current step sparring doesn't have an assigned file"
          this.openModal('error')
        }


        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Self Defense', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let self_defense_by_rank = null;
        try {
          self_defense_by_rank = await selfDefenseTechniquesAPIService.getSelfDefenseTechniquesByRank(rank.id, this.$router);
          const idIndexMap = Object.fromEntries(
            all_self_defs.map((item, index) => [item.id, index])
          );
          self_defense_by_rank.sort((a, b) => idIndexMap[a.self_defense_techniques_id] - idIndexMap[b.self_defense_techniques_id]);
          for (let item of self_defense_by_rank){
            for (let self_def of all_self_defs){
              if (y > (pageHeight - 10)){
                doc.addPage()
              }
              if (self_def.id === item.self_defense_techniques_id){
                if (self_def.description) {
                  let printName = ''
                  if (self_def.poom_dan.name !== 'Both'){
                    printName = self_def.poom_dan.name + ' - '
                  }
                  printName +=  self_def.description
                  doc.text(printName, x + 5, y);
                }
                y += 5;
                break
              }
            }
          }
          y += 3;
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self defense techniques to ranks: ';
          this.openModal('error');
        }

        if (y > (pageHeight - 20)){
          doc.addPage()
        }
        doc.setFontSize('12');
        doc.text('Breaking', x, y);
        doc.setFontSize('8');
        y += 3;
        doc.line(x, y, pageWidth - 10, y);
        y += 5;
        let breaking_by_rank = null;
        try {
          breaking_by_rank = await breakingTechniquesAPIService.getBreakingTechniquesByRankID(rank.id, this.$router);
          const techniques = breaking_by_rank.breaking_techniques;
          for (const technique of techniques) {
            if (y > (pageHeight - 10)){
              doc.addPage()
            }
            if (technique.description) {
              doc.text(technique.description, x + 5, y);
            }
            y += 5;
          }
        } catch (e) {
          this.error_txt = 'Unresolved data error getting self techniques to ranks: ' + e;
          this.openModal('error');
        }

        x = 10;
        y = 10;

        if (this.selectedData.indexOf(order.id) !== this.selectedData.length - 1){
          doc.addPage()
        } else {
          fileName = rank.name + " Curriculum.pdf"
        }
      }
    }
  }
  if (this.givenData.length > 1){
    fileName = this.printName + endOfFileName
  }
  doc.save(fileName);
}
