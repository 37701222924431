<template>
  <div>
    <el-button v-if="next_rank && !next_rank.is_sub_rank && (!current_rank.is_sub_rank || ranks.filter(rank => rank.head_rank_id === current_rank.head_rank_id && !this.completed_ranks.some(data => data.rank_id === rank.id)).length === 1)"
               type="success" @click="changeRank(user_id, next_rank.id, {passed_rank_id: current_rank.id})" icon="">
      Promote to {{next_rank.name}}
    </el-button>
    <el-select v-else filterable v-model="selected_rank_id" placeholder="Promote to Sub Rank ..." style="width: 100%;"
               @change="changeRank(user_id, selected_rank_id, {passed_rank_id: current_rank.id})">
      <el-option v-for="item in selectable_ranks"
                 :key="item.id" :value="item.id" :label="item.name + isCompleted(item.id)">
      </el-option>
    </el-select>
    <el-select v-if="showChangeSelect" filterable v-model="selected_rank_id" placeholder="Change Rank to ..." style="width: 100%; margin-top: 10px"
               @change="changeRank(user_id, selected_rank_id)">
      <el-option v-for="item in ranks"
                 :key="item.id" :value="item.id" :label="item.name + isCompleted(item.id)">
      </el-option>
    </el-select>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
      </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Option, Select, Button, Dialog } from 'element-ui';
import { UserAPIService } from 'src/servicehandlers/UsersAPIService';
const usersAPIService = new UserAPIService();
export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  name: "Promotion",
  props: {
    current_rank: Object,
    next_rank: Object,
    last_rank: Object,
    user_id: Number,
    ranks: Array,
    showChangeSelect: {
      type: Boolean,
      default: true,
    },
    update_backend: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modals: {
        error: false,
      },
      error_txt: '',
      selected_rank_id: null,
      completed_ranks: [],
      user: null,
      selectable_ranks: [],
    }
  },
  mounted() {
    this.getCompletedRanks()
    this.selectable_ranks = this.ranks.filter(data => data.head_rank_id === this.current_rank.head_rank_id).filter(data => data.id !== this.current_rank.id)
    if (this.next_rank.is_sub_rank){
      this.selectable_ranks = this.ranks.filter(data => data.head_rank_id === this.next_rank.head_rank_id)
    }
  },
  methods: {
    changeRank(user_id, rank_id, promote = false){
      if (this.update_backend) {
        return usersAPIService.changeUserRankTo(user_id, rank_id, promote, this.$router)
          .then(() => {
            this.reloadData()
            this.selected_rank_id = null
            this.getCompletedRanks()
          })
          .catch((e) => {
            this.error_txt = 'Error promoting user'
            this.openModal('error')
          })
      }
    },
    getCompletedRanks(){
      return usersAPIService.getCompletedRanksForUser(this.user_id, this.$router)
        .then(response => {
          this.completed_ranks = response
        })
        .catch((e) => {
          this.error_txt = 'Error getting completed ranks: ' + e
          this.openModal('error')
        })
    },
    getUser(){

    },
    uploadCertToServer(){
      //let temp =
    },
    reloadData(){
      this.$emit('reload')
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    isCompleted(rank_id){
      if (this.completed_ranks.some(data => data.rank_id === rank_id)){
        return ' (Completed)'
      }
      return ''
    },
  }
}
</script>

<style scoped>

</style>
