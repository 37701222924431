import JsPDF from 'jspdf';
import 'jspdf-autotable';
import {nyalaNormalBase64} from "../../Pages/Admin/Fonts/nyala-normal";
import {lastNinjaBase64} from "../../Pages/Admin/Fonts/lastninja";
import {nyalaBoldBase64} from "../../Pages/Admin/Fonts/nyala-bold";
import {parchmentNormalBase64} from "../../Pages/Admin/Fonts/Parchment MF-normal";
export async function printBBTRollSheets() {

  /*this.filteredData = this.givenData

  this.filteredData.sort((a, b) => {
    // First, compare by rank.order
    if (a.rank.order !== b.rank.order) {
      return a.rank.order - b.rank.order; // Ascending order for rank
    }
    // If rank.order is the same, compare by user.age in descending order
    return b.user.age - a.user.age; // Descending order for age
  });*/

  const doc = new JsPDF({
    orientation: "portrait",
    format: 'letter',
  });
  let fileName = this.printName + ' ' + this.printByTypes[this.printType].name + ' '
  let empty = true
  let count = 0

  function addFontToPDF(pdf) {
    pdf.addFileToVFS('nyala.ttf', nyalaNormalBase64);
    pdf.addFont('nyala.ttf', 'nyala', 'normal');
    pdf.addFileToVFS('lastninja.ttf', lastNinjaBase64);
    pdf.addFont('lastninja.ttf', 'lastninja', 'normal');
    pdf.addFileToVFS('nyala-bold.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-bold.ttf', 'nyala', 'bold');
    pdf.addFileToVFS('nyala-italics.ttf', nyalaBoldBase64);
    pdf.addFont('nyala-italics.ttf', 'nyala', 'italic');
    pdf.addFileToVFS('Parchment.ttf', parchmentNormalBase64);
    pdf.addFont('Parchment.ttf', 'Parchment', 'normal');
  }

  addFontToPDF(doc)

  doc.setFontSize('10');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const halfPage = pageWidth / 2;
  let x = 10;
  let y = 10;

  function printTopInfo(printName, programName){
    let string = printName + ' - ' + programName + ' - Roll Sheet'
    doc.setFontSize('16')
    y = 10
    x = 10
    doc.setFont('times', 'bold')
    let textWidth = doc.getTextWidth(string)
    doc.text(string, halfPage - (textWidth / 2), y);
    y += 10;
  }

  const columns = ["", "Name", "Kicks", "Tech 1", 'SD 1', 'Oly Spar', 'Free Spar'];
  let rows = []

  switch (this.printByTypes[this.printType].name){
    case 'Program':
      for (let id of this.selectedData) {
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        empty = false
        count++

        let programName = ''
        for (let item of this.filteredData){
          if (item.id === id){
            programName = item.name
            break
          }
        }
        printTopInfo(this.printName, programName)

        rows = []
        for (let order of this.givenData){
          if (order.program.id === id){
            let temp = [order.order, order.user.first_name + ' ' + order.user.last_name]
            rows.push(temp)
          }
        }

        doc.autoTable({
          head: [columns],
          body: rows,
          styles: {
            lineColor: [0, 0, 0],  // Black borders
            lineWidth: 0.5,        // Slightly thicker border
            fontSize: 10,          // Default font size for table
            font: 'helvetica',     // Helvetica font for the entire table
          },
          headStyles: {
            fillColor: [255, 255, 255],  // Blue background for header
            textColor: [0, 0, 0],// White text in header
            fontSize: 12,              // Larger font for headers
            fontStyle: 'bold',         // Bold text for headers
          },
          bodyStyles: {
            fillColor: [255, 255, 255], // Light gray background for body rows
            textColor: [0, 0, 0],       // Black text in body rows
            lineColor: [0, 0, 0], // Light gray borders for body rows
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // White background for alternating rows
          },
          margin: { top: 20 },
        });

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      break
    case 'Rank':
      for (let id of this.selectedData) {
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        empty = false
        count++

        let rankName = ''
        for (let item of this.filteredData){
          if (item.id === id){
            rankName = item.name
            break
          }
        }
        printTopInfo(this.printName, rankName)

        rows = []
        for (let order of this.givenData){
          if (order.rank.id === id){
            let temp = [order.order, order.user.first_name + ' ' + order.user.last_name]
            rows.push(temp)
          }
        }

        doc.autoTable({
          head: [columns],
          body: rows,
          styles: {
            lineColor: [0, 0, 0],  // Black borders
            lineWidth: 0.5,        // Slightly thicker border
            fontSize: 10,          // Default font size for table
            font: 'helvetica',     // Helvetica font for the entire table
          },
          headStyles: {
            fillColor: [255, 255, 255],  // Blue background for header
            textColor: [0, 0, 0],// White text in header
            fontSize: 12,              // Larger font for headers
            fontStyle: 'bold',         // Bold text for headers
          },
          bodyStyles: {
            fillColor: [255, 255, 255], // Light gray background for body rows
            textColor: [0, 0, 0],       // Black text in body rows
            lineColor: [0, 0, 0], // Light gray borders for body rows
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // White background for alternating rows
          },
          margin: { top: 20 },
        });

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      break
    case 'Tests':
      for (let id of this.selectedData) {
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        empty = false
        count++

        let rankName = ''
        for (let item of this.filteredData){
          if (item.id === id){
            rankName = item.name
            break
          }
        }
        printTopInfo(this.printName, rankName)

        rows = []
        for (let order of this.givenData){
          if (order.testing_type_id === id){
            let temp = [order.order, order.user.first_name + ' ' + order.user.last_name]
            rows.push(temp)
          }
        }

        doc.autoTable({
          head: [columns],
          body: rows,
          styles: {
            lineColor: [0, 0, 0],  // Black borders
            lineWidth: 0.5,        // Slightly thicker border
            fontSize: 10,          // Default font size for table
            font: 'helvetica',     // Helvetica font for the entire table
          },
          headStyles: {
            fillColor: [255, 255, 255],  // Blue background for header
            textColor: [0, 0, 0],// White text in header
            fontSize: 12,              // Larger font for headers
            fontStyle: 'bold',         // Bold text for headers
          },
          bodyStyles: {
            fillColor: [255, 255, 255], // Light gray background for body rows
            textColor: [0, 0, 0],       // Black text in body rows
            lineColor: [0, 0, 0], // Light gray borders for body rows
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // White background for alternating rows
          },
          margin: { top: 20 },
        });

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      break
    case 'Groups':
      for (let id of this.selectedData) {
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        empty = false
        count++

        let rankName = ''
        for (let item of this.filteredData){
          if (item.id === id){
            rankName = item.name
            break
          }
        }
        printTopInfo(this.printName, rankName)
        let currentY = 20

        for (let i = 1; i <= 5; i++){
          rows = []
          for (let order of this.givenData){
            if (order.testing_type_id === id){
              if (order.group.group_id === i - 1){
                let temp = [order.order, order.user.first_name + ' ' + order.user.last_name]
                rows.push(temp)
              }
            }
          }

          if (rows.length > 0) {
            doc.text('Group ' + i, 20, currentY)
            currentY += 5

            doc.autoTable({
              startY: currentY,
              head: [columns],
              body: rows,
              styles: {
                lineColor: [0, 0, 0],  // Black borders
                lineWidth: 0.5,        // Slightly thicker border
                fontSize: 10,          // Default font size for table
                font: 'helvetica',     // Helvetica font for the entire table
              },
              headStyles: {
                fillColor: [255, 255, 255],  // Blue background for header
                textColor: [0, 0, 0],// White text in header
                fontSize: 12,              // Larger font for headers
                fontStyle: 'bold',         // Bold text for headers
              },
              bodyStyles: {
                fillColor: [255, 255, 255], // Light gray background for body rows
                textColor: [0, 0, 0],       // Black text in body rows
                lineColor: [0, 0, 0], // Light gray borders for body rows
              },
              alternateRowStyles: {
                fillColor: [255, 255, 255], // White background for alternating rows
              },
            });

            currentY = doc.lastAutoTable.finalY + 15;
          }
        }

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      break
    case 'Rank Level':
      for (let id of this.selectedData) {
        let index = this.selectedData.indexOf(id) + 1
        this.precentage = (index / this.selectedData.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        empty = false
        count++

        let rankName = ''
        for (let item of this.filteredData){
          if (item.id === id){
            rankName = item.name
            break
          }
        }
        printTopInfo(this.printName, rankName)

        rows = []
        for (let order of this.givenData){
          if (order.rank.rank_type_id === id){
            let temp = [order.order, order.user.first_name + ' ' + order.user.last_name]
            rows.push(temp)
          }
        }

        doc.autoTable({
          head: [columns],
          body: rows,
          styles: {
            lineColor: [0, 0, 0],  // Black borders
            lineWidth: 0.5,        // Slightly thicker border
            fontSize: 10,          // Default font size for table
            font: 'helvetica',     // Helvetica font for the entire table
          },
          headStyles: {
            fillColor: [255, 255, 255],  // Blue background for header
            textColor: [0, 0, 0],// White text in header
            fontSize: 12,              // Larger font for headers
            fontStyle: 'bold',         // Bold text for headers
          },
          bodyStyles: {
            fillColor: [255, 255, 255], // Light gray background for body rows
            textColor: [0, 0, 0],       // Black text in body rows
            lineColor: [0, 0, 0], // Light gray borders for body rows
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // White background for alternating rows
          },
          margin: { top: 20 },
        });

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      break
  }

  if (!empty) {
    fileName += " Roll Sheets.pdf"
    doc.save(fileName);
  } else {
    this.error_txt = "No Competitors selected"
    this.openModal('error')
  }
}
