import CommonAPIService from './CommonHandler';

export class RanksAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  getRanks(router) {
    const url = '/api/ranks';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRanksById(rankId, router){
    const url = `/api/ranks/${rankId}`
    return this.CommonAPIService.postCall(url, '', router)
  }

  getRanksToPoomsae(router) {
    const url = '/api/ranks/poomsae';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getRanksToTechniques(router) {
    const url = '/api/ranks/techniques';
    return this.CommonAPIService.getCall(url, '', router);
  }

  addRank(rank, router) {
    const url = '/api/ranks';
    return this.CommonAPIService.postCall(url, rank, router);
  }

  updateRank(rankId, rank, router) {
    const url = `/api/ranks/${rankId}`;
    return this.CommonAPIService.putCall(url, rank, router);
  }

  deleteRank(rankId, router) {
    const url = `/api/ranks/${rankId}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
}

export default RanksAPIService;
