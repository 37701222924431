import JsPDF from 'jspdf';
import OutlineAPIService from "../../../../servicehandlers/OutlinesAPIService";
const outlineAPIService = new OutlineAPIService();

export async function printScores() {

  let scores_objects = {};
  // pull the required items we will be needing from the DB
  const outlines = [];

  for (const data of this.givenData) {
    if (this.selectedData.includes(data.id)){
      if (data.outline){
        await outlineAPIService.getOutlineByID(data.outline.id, this.$router)
          .then((outline) => {
            let temp = {
              outline: outline,
              rank: data.rank,
            }
            outlines.push(temp);
          })
          .catch((error) => {
            this.error_txt = 'Lock failed: ' + error;
            this.openModal('error');
          });
      } else {
        this.error_txt = 'One or more of the selected competitors is missing an outline'
        this.openModal('error')
        this.loading = false
        return
      }
    }
  }
  return Promise.all(outlines)
    .then(() => {
      return outlineAPIService.getBase64File('score_view_items.png', this.$router)
    })
    .then(async (response) => {
      const doc = new JsPDF({
        orientation: "landscape",
        format: 'letter',
      });
      const imgData = response;

      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      const halfPage = pageWidth / 2;
      let count = 0

      for (const data of outlines) {
        let index = outlines.indexOf(data) + 1
        this.precentage = (index / outlines.length) * 100
        this.precentage = parseFloat((Math.round(this.precentage * 100) / 100).toFixed(1))

        let outline = data.outline
        count++
        scores_objects = {};
        this.setTechniqueNames(outline);
        // get all the scores into a nice bundle so we can print it
        scores_objects = await this.calculateScoresForOutline(outline);

        let x = 5;
        let y = 10;

        if (this.event && this.event.event_type_id === 3){
          for (let order of this.givenData){
            if (order.outline && order.outline.id === outline.id){
              doc.text(order.order + ' ', pageWidth - 10, y, 'right')
              break
            }
          }
        }
        doc.setFontSize('12');
        doc.text(outline.user.first_name + ' ' + outline.user.last_name + '-' + outline.weight + ' lbs,  Weight Class ' + scores_objects.userClass.value + ', ' + outline.user.age + ' years', x, y);
        x = 118;
        doc.setFontSize('20');
        doc.text(outline.description, x, y);
        doc.setFontSize('12');
        x = 200
        doc.text('Average Board Size: ' + scores_objects.userClass.avgBrdSize.name, x, y)
        x = 10
        outline.updatedAt = new Date(outline.updatedAt)

        let textWidth = doc.getTextWidth('Last saved: ' + outline.updatedAt.toLocaleDateString())
        doc.text('Last saved: ' + outline.updatedAt.toLocaleDateString(), pageWidth - textWidth - 10, pageHeight - 10)

        y += 5;
        x = 10;
        doc.text('Required Number of Breaks: ' + data.rank.number_of_breaks, x, y);
        x += 70;
        doc.text('Required Speed Breaks: ' + data.rank.required_speed_breaks, x, y);
        x += 60;
        doc.text('Required Power Breaks: ' + data.rank.required_power_breaks, x, y);
        x += 60;
        doc.text('Breaking Point Minimum: ' + data.rank.breaking_point_minimum, x, y);
        x = 10;
        y += 5;

        // now display the requirements met objects
        if (outline.required_number_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + outline.breaking_techniques_to_outlines.length, x, y);
        }
        x += 70;

        if (outline.required_speed_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_speed_breaks, x, y);
        }
        x += 60;

        if (outline.required_power_breaks) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_power_breaks, x, y);
        }
        x += 60;

        if (outline.required_minimum_score) {
          doc.setTextColor(0, 255, 0);
          doc.text('Have: ' + scores_objects.total_average_score, x, y);
        } else {
          doc.setTextColor(255, 0, 0);
          doc.text('Have: ' + scores_objects.total_average_score, x, y);
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize('8');

        x = 10;
        y += 30;

        x = 10;
        y = 35;

        doc.setFontSize('8');
        // set each technique column up, one by one
        doc.text('Order', x, y);
        x += 17;
        doc.text('Technique', x, y);
        x += 85;
        doc.text('Board Size', x, y);
        x += 23;
        doc.text('# of Boards', x, y);
        x += 23;
        doc.text('Attempts', x, y);
        x += 15;
        doc.addImage(imgData, 'PNG', x, 22, 50, 20);
        x += 50;
        let tmp_x = x;
        let tmp_y = y + 3;
        doc.text('Possible', x, y);
        doc.text('Score', tmp_x, tmp_y);
        x += 15;
        doc.text('Penalties', x, y);
        x += 15;
        doc.text('Technical', x, y);
        tmp_x = x;
        tmp_y = y + 3;
        doc.text('Score', tmp_x, tmp_y);
        x += 17;
        // doc.text('Final Score', x, y);
        // x += 17;

        for (const technique of outline.breaking_techniques_to_outlines) {
          const board_id = technique.board_id;
          x = 10;
          y += 10;
          doc.text(technique.order.toString(), x, y);
          x += 17;
          if (technique.techName.toString().length < 65) {
            doc.text(technique.techName.toString(), x, y);
          } else {
            doc.text(technique.techName.toString().substring(0, 65) + '-', x, y)
            doc.text(technique.techName.toString().substring(65), x, y + 5)
          }
          x += 85;
          for (const board of this.dropdown_boards) {
            if (board.id === board_id) {
              doc.text(board.name, x, y);
              break;
            }
          }
          x += 23;

          doc.text(technique.num_boards.toString(), x, y);
          x += 24;
          doc.text(technique.attempts.toString(), x, y);
          x += 17;
          doc.rect(x, y - 3, 5, 5);
          if (technique.changed_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.dropped_a_board) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.partial_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 13;
          doc.rect(x, y - 3, 5, 5);
          if (technique.didnt_break) {
            doc.text('X', x + 2, y + 1);
          }
          x += 10;

          doc.text(scores_objects[technique.id].line_score.toString(), x, y);
          x += 15;
          doc.text(scores_objects[technique.id].penalties.toString(), x, y);
          x += 15;
          doc.text(scores_objects[technique.id].total_score.toString(), x, y);
          //x += 17;
          // doc.text(this.scores_objects[technique.id].total_score.toString(), x, y);
        }

        // total boards
        y += 7;
        x = 10 + 17 + 75 + 23;
        doc.text("Total Boards: " + scores_objects.total_boards.toString(), x, y);
        x += 24 + 10 + 10 + 12 + 12 + 11;
        doc.text('Possible Score: ' + scores_objects.total_average_score.toString(), x, y);
        x += 12 + 15 + 8;
        doc.text('Tech Score: ' + scores_objects.final_technical_score.toString(), x, y);

        y += 10;
        x = 40;

        // second part, totals
        doc.text('J1', x, y);
        x += 15;
        doc.text('J2', x, y);
        x += 15;
        doc.text('J3', x, y);
        x += 15;
        doc.text('J4', x, y);
        x += 15;
        doc.text('J5', x, y);
        x += 15;
        doc.text('Time', x, y);
        x += 15;
        doc.text('Presentation', x, y);
        x += 45;
        doc.text('Technical Score', x, y);
        x += 25;
        doc.text('Final Score', x, y);

        y += 10;
        x = 40;

        if (!outline.j1) {
          outline.j1 = 0;
        }
        if (!outline.j2) {
          outline.j2 = 0;
        }
        if (!outline.j3) {
          outline.j3 = 0;
        }
        if (!outline.j4) {
          outline.j4 = 0;
        }
        if (!outline.j5) {
          outline.j5 = 0;
        }
        doc.text(outline.j1.toString(), x, y);
        x += 15;
        doc.text(outline.j2.toString(), x, y);
        x += 15;
        doc.text(outline.j3.toString(), x, y);
        x += 15;
        doc.text(outline.j4.toString(), x, y);
        x += 15;
        doc.text(outline.j5.toString(), x, y);
        x += 15;
        if (!outline.time) {
          outline.time = 0;
        }
        doc.text(outline.time.toString(), x, y);
        x += 15;
        if (!scores_objects.presentation) {
          scores_objects.presentation = 0;
        }
        doc.text(scores_objects.presentation.toFixed(2).toString(), x, y);
        x += 45;
        if (!scores_objects.total_score) {
          scores_objects.total_score = 0;
        }
        scores_objects.final_technical_score = parseInt(scores_objects.final_technical_score);
        doc.text(scores_objects.final_technical_score.toString(), x, y);
        x += 25;
        if (!scores_objects.total_score) {
          scores_objects.total_score = 0;
        }
        if (scores_objects.total_average_score) {
          scores_objects.total_average_score = parseFloat(scores_objects.total_average_score);
          scores_objects.total_average_score = scores_objects.total_average_score.toFixed(2);
        } else {
          scores_objects.total_average_score = 0;
        }
        doc.text(scores_objects.total_average_score.toString(), x, y);

        // now put the totals in at the bottom
        y += 10;
        x = 10;

        const boards = {};
        doc.text('Total Board Breakdown: ', x, y);
        for (const board of this.dropdown_boards) {
          boards[board.id] = {
            name: board.name,
            total: 0,
          };
        }
        y += 6;
        for (const technique_to_outline of outline.breaking_techniques_to_outlines) {
          // first get the number of the board, add all them together
          boards[technique_to_outline.board_id].total += parseInt(technique_to_outline.num_boards);
        }
        const keys = Object.keys(boards);
        for (const key of keys) {
          const board = boards[key];
          if (board.name !== 'N/A') {
            x = 10;
            doc.text(board.name + 's: ' + board.total.toString(), x, y);
            y += 6;
          }
        }
        x += 24;

        if (count !== this.selectedData.length){
          doc.addPage();
        }
      }
      let fileName = this.printName + ' Score Sheet.pdf'
      doc.save(fileName);
    })
    .catch((error) => {
      this.loading = false;
      this.error_txt = 'Error adding row: ' + error;
      this.openModal('error');
    });
}
